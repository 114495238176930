import styled from 'styled-components';

export const SectionWrapper = styled.div`
    padding: 20px 30px;
    width: 100%;
    height: calc(100vh - 159.5px);
    overflow: auto;
    .section-actions {
        position: fixed;
        display: flex;
        background-color: white;
        flex-direction: column;
        right: 0;
        top: 40vh;
        padding: 12px 16px;
        border: 1px solid #bfbfbf;
        border-right: 0;
    }
    .section-action-icon {
        font-size: 32px;
        cursor: pointer;
        margin: 5px 0 5px 0;
    }
    .element-wrapper {
        width: 90%;
        margin-bottom: 10px;
        background: white;
        border: 1px solid #ccc;
        padding: 10px 20px;
        border-radius: 10px;  
    }
    .element-type {
        display: flex;
        justify-content: space-between;
        .ant-form-item {
            width: 20%;
        }
        .dynamic-delete-button {
            padding-top: 12px;
            &:hover {
                color: #f5222d;
                font-weight: bold;
            }
        }
        .dynamic-collapse-button {
            padding-top: 12px;
            &:hover {
                color: #1890ff;
                font-weight: bold;
            }
        }
    }
    .dynamic-delete-button {
        &:hover {
            color: #f5222d;
            font-weight: bold;
        }
    }
    .dynamic-collapse-button {
        &:hover {
            color: #1890ff;
            font-weight: bold;
        }
    }
    .element-value {
        width: 80%;
    }
    .collapsed-item {
        width: '12%';
        padding: 20;
        background-color: 'blue';
        border-radius: '10px 0px 0px 10px';
    }
    .row-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .graph-container {
        width: 100%;
        .graph-type {
            width: 200px;
            margin-right: 20px;
        }
        .graph-search {
            width: 500px;
        }
        .graph-item {
            margin-right: 20px;
            width: 200px;
        }
    }
    .table-container {
        .table-item {
            width: 30%;
            margin-right: 20px;
        }
        .table-value {
            width: 100%;
        }
    }
    .image-container {
        .image-item {
            width: 30%;
            margin-right: 20px;
        }
        .image-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .image-tag {
            position: relative;
            margin-top: 25px;
            &::before {
                color: rgba(0, 0, 0, 0.85);
                content: 'Preview:';
                position: absolute;
                top: -25px;
                left: 20px;
            }
        }
    }
`;