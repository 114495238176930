import React from 'react';
import { Tabs } from 'antd';
import { TabContainerWrapper } from './TabContainerStyle';
import AddReport from './Add/AddContainer';
import EditReport from './Edit/EditContainer';
import UserContainer from '../../Users/UserContainer';

const { TabPane } = Tabs;


const TabContainer = ({setReportDetails, active_report}) => {

    const handleTabChange = (key) => {
        if(key !== 1)
            setReportDetails({id: null, version: null, title: null});
    }

    return (
        <TabContainerWrapper>
            <Tabs defaultActiveKey="1" size="large" onChange={handleTabChange}>
                <TabPane tab="Add" key="1">
                    <AddReport setReportDetails={setReportDetails} active_report={active_report}/>
                </TabPane>
                <TabPane tab="Edit" key="2">
                    <EditReport mode="unpublished"/>
                </TabPane>
                <TabPane tab="Edit Config" key="3">
                    <EditReport mode="published"/>
                </TabPane>
                <TabPane tab="Users" key="4">
                    <UserContainer />
                </TabPane>
            </Tabs>
        </TabContainerWrapper>
    )
    
}

export default TabContainer;