import axios from 'axios';
import config from '../../../../config/config';

import { notification } from 'antd';
import { setSectionDetails } from '../../ReportActions';

export const getSection = (section_id, user_id, callback) => {
    return function(dispatch) {
        axios.get(`${config.api.base_url}${config.api.get_section}?section_id=${section_id}${user_id ? '&user_id=' + user_id : ''}`,
        {headers: {authorization: localStorage.getItem('token')}})
        .then(response => {
            let temp_section = {
                section_id: response.data.section_id,
                title: response.data.title,
                content: response.data.content
            }
            callback(response.data.content, response.data.pages, response.data.config);
            dispatch(setSectionDetails(temp_section));
        }).catch(error => {
            console.log(error);
            notification.error({
                message: 'Error!',
                description: 'Something went wrong. Please contact your admin!'
            })
        });
    }
}

export const getPrevImage = (report_id, version, callback) => {
    axios.get(`${config.api.base_url}${config.api.prev_image}?id=${report_id}&version=${version}`,
    {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        callback(response.data);
    }).catch((error, response) => {
    });
}

export const getGraph = (id) => {
    return axios.get(`${config.api.base_url}/graphs?id=${id}`, {headers: {authorization: localStorage.getItem('token')}});
}