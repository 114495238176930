import React from 'react';
import { connect } from 'react-redux';
import { Button, Typography, Affix, notification, Tooltip, Modal, Icon, Menu, Dropdown, Upload } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom';
import { logoutRequest } from '../Login/LoginActions';
import { history } from '../../history';
import { HeaderContainerWrapper } from './SeachContainerStyle';
import TabContainer from './Tabs/TabContainer';
import ReportContainer from '../Report/ReportContainer';
import UserConfig from '../Users/UserConfig/UserConfigComponent';
import { setReportDetails, setSectionDetails } from '../Report/ReportActions';
import { CSVLink } from "react-csv";
import { publishReport, deleteReport, uploadPDF, uploadTOC, 
        downloadPriceSheet, uploadPriceSheet, uploadHeading, exportGraphs } from './Tabs/Add/AddActions';

const { Title } = Typography;
const { SubMenu } = Menu;
const confirm = Modal.confirm;

const props = {
    action: null,
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        uploadPDF(file);
      }
    }
}

class Search extends React.Component {

    state = {
        csvData: [],
        graphData: [],
        down_icon: true,
        reload_toc: false
    }

    handleUploadPDF = (file) => {
        let report = this.props.location.pathname;
        let report_id = report.split('/')[2] || null;
        let version = report.split('/')[3] || null;
        uploadPDF(file, report_id, version, (err, res) => {
            if(err) {
                notification.error({
                    message: 'Error',
                    description: 'Failed to upload the PDF'
                })
            } else {
                notification.success({
                    message: 'Success',
                    description: 'Succeded in uploading the PDF'
                })
            }
        })
    }

    handleUploadTOC = (file) => {
        let report = this.props.location.pathname;
        let report_id = report.split('/')[2] || null;
        let version = report.split('/')[3] || null;
        uploadTOC(file, report_id, version, (err, res) => {
            if(err) {
                notification.error({
                    message: 'Error',
                    description: 'Failed to upload the TOC'
                })
            } else {
                notification.success({
                    message: 'Success',
                    description: 'Succeded in uploading the TOC'
                })
                this.handleReloadTOC(true);
            }
        })
    }

    handleReloadTOC = (value) => {
        this.setState({
            reload_toc: value
        })
    }

    handleDownIcon = (value) => {
        this.setState({
            down_icon: value
        })
    }

    componentDidMount = () => {
        if(localStorage.getItem('token') === "null" || !localStorage.getItem('token')) {
            history.push('/login');
        }
    }

    showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure delete this report?',
            content: 'This is an irreversible process',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk : () => {
                this.handleDelete();
            },
            onCancel () {
            },
        });
    }

    handleHome = () => {
        let temp_report = {
            id: null,
            version: null,
            title: null
        }
        let temp_section = {
            section_id: null,
            title: null,
            content: []
        }
        this.props.setReportDetails(temp_report);
        this.props.setSectionDetails(temp_section);
        history.push('/');
    }

    handleLogout = () => {
        try {
            this.props.logoutRequest(localStorage.getItem('token'));
            localStorage.setItem('token', null);
            // history.push('/login');
        } catch (error) {
            console.log(error);
            notification['error']({
                message: 'Error',
                description: 'Error Logging out',
            });
        }
    }

    handlePublish = async () => {
        try {
            let report = this.props.location.pathname;
            let report_id = report.split('/')[2] || null;
            let version = report.split('/')[3] || null;
            let response = await publishReport(report_id, version);
            if(response.status === 200) {
                notification['success']({
                    message: 'Success',
                    description: 'Succesfully published report',
                });
            } else {
                notification['error']({
                    message: 'Error',
                    description: response.data && response.data.message ? response.data.message : 'Error publishing report'
                });
            }
        } catch (error) {
            console.log(error);
            notification['error']({
                message: 'Error',
                description: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Error publishing report'
            });
        }
    }

    handleDelete = async () => {
        try {
            let report = this.props.location.pathname;
            let report_id = report.split('/')[2] || null;
            let version = report.split('/')[3] || null;
            let response = await deleteReport(report_id, version);
            if(response.status === 200) {
                notification['success']({
                    message: 'Success',
                    description: 'Succesfully deleted report',
                });
                this.handleHome();
                history.push('/');
            } else {
                notification['error']({
                    message: 'Error',
                    description: response.data && response.data.message ? response.data.message : 'Error deleting report',
                });
            }
        } catch (error) {
            console.log(error);
            notification['error']({
                message: 'Error',
                description: 'Error deleting report',
            });
        }
    }

    handlePriceSheetDownload = async (report_id, version) => {
        try {
            let response = await downloadPriceSheet(report_id, version);
            this.setState({
                csvData: response.data
            });
        } catch (err) {
            console.log(err);
            notification.error({
                message: 'Error',
                description: err.response && err.response.data && typeof(err.response.data) === 'string' ? err.response.data : 'Cannot download price sheet. Contact an administrator.'
            })
        }
    }

    handleUploadPriceSheet = async (file) => {
        try {
            let response = await uploadPriceSheet(file);
            if(response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: 'Uploaded price sheet!'
                });
                let report = this.props.location.pathname;
                let report_id = report.split('/')[2] || null;
                let version = report.split('/')[3] || null;
                this.handlePriceSheetDownload(report_id, version);
            }
        } catch (err) {
            console.log(err);
            notification.error({
                message: 'Error',
                description: err.response && err.response.data ? err.response.data : 'Cannot download price sheet. Contact an administrator.'
            })
        }
    }

    handleUploadHeadings = async (file) => {
        try {
            let report = this.props.location.pathname;
            let report_id = report.split('/')[2] || null;
            let response = await uploadHeading(file, report_id);
            if(response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: 'Uploaded headings for graphs'
                });
                let report = this.props.location.pathname;
                let report_id = report.split('/')[2] || null;
                let version = report.split('/')[3] || null;
                this.handleExportGraphs(report_id, version);
            }
        } catch (err) {
            console.log(err);
            notification.error({
                message: 'Error',
                description: 'Cannot upload graph.'
            })
        }
    }

    handleExportGraphs = async (report_id, version) => {
        try {
            let response = await exportGraphs(report_id, version);
            if(response.status === 200) {
                let data = [];
                for(let index in response.data) {
                    let temp = response.data[index];
                    if(response.data[index] && response.data[index]['tags']) {
                        for(let tag in response.data[index]['tags']) {
                            temp[tag] = response.data[index]['tags'][tag];
                        }
                        delete temp['tags'];
                        data.push(temp);
                    }
                }
                this.setState({
                    graphData: data
                })
            }
        } catch (err) {
            console.log(err);
            notification.error({
                message: 'Error',
                description: 'Cannot export graphs.'
            })
        }
    }

    render() {

        const config = this.props.location.pathname.indexOf('config') != -1;
        
        const menu =
        <Menu>
            <Menu.Item onClick={()=>this.handlePublish()}>
                <Icon type="save"/> Publish
            </Menu.Item>
            <Menu.Item onClick={()=>this.showDeleteConfirm()}>
                <Icon type="delete"/> Delete
            </Menu.Item>
            <SubMenu title={ <span><Icon type="dollar" style={{marginRight: 10}}/>Price</span>}>
                <Menu.Item>
                    <CSVLink data={this.state.csvData} 
                    filename={this.props.location.pathname && this.props.location.pathname.split('/')[2] ? this.props.location.pathname.split('/')[2] + '_price_sheet.csv' : 'NA.csv' }>
                        <Icon type="download"/> <span style={{marginLeft: 7}}>Download Price Sheet</span>
                    </CSVLink>
                </Menu.Item>
                <Menu.Item>
                    <Upload {...props} accept={'.csv'} fileList={[]} customRequest={(file, fileList) => {this.handleUploadPriceSheet(file)}}>
                        <Icon type="upload"/><span style={{marginLeft: 10}}>Upload Price Sheet</span>
                    </Upload>
                </Menu.Item>
            </SubMenu>
            <SubMenu title={<span><Icon type="line-chart" style={{marginRight: 10}}/>Graph</span>}>
                <Menu.Item>
                    <Upload {...props} accept={'.csv'} fileList={[]} customRequest={(file, fileList) => {this.handleUploadHeadings(file)}}>
                        <Icon type="upload"/> <span style={{marginLeft: 7}}>Upload Graphs</span>
                    </Upload>
                </Menu.Item>
                <Menu.Item>
                    <CSVLink data={this.state.graphData} 
                        filename={this.props.location.pathname && this.props.location.pathname.split('/')[2] ? this.props.location.pathname.split('/')[2] + '_graphs.csv' : 'graph.csv' }>
                        <Icon type="download"/> <span style={{marginLeft: 7}}>Export Graphs</span>
                    </CSVLink>
                </Menu.Item>
            </SubMenu>
            <Menu.Item>
                <Upload {...props} accept={'.csv'} fileList={[]} customRequest={(file, fileList) => {this.handleUploadTOC(file)}}>
                    <Icon type="upload"/> <span style={{marginLeft: 7}}>Upload TOC</span>
                </Upload>
            </Menu.Item>
        </Menu>

        const action_dropdown = this.props.active_report.version && !config ? 
        <Dropdown overlay={menu} trigger={['click']}>
            <Button type="primary" className="right-button" onFocus={() => {this.handleDownIcon(!this.state.down_icon)}} onBlur={() => {this.handleDownIcon(!this.state.down_icon)}}><Icon style={{fontSize: 12, transform: `rotate(${this.state.down_icon?'0deg':'180deg'})`, transition: '300ms ease all'}} type='down' />Actions</Button>
        </Dropdown> : '';

        return (
            <div>
                <Affix>
                    <HeaderContainerWrapper>
                        <Button type="primary" className="left-button" onClick={() => {this.handleHome()}}><Icon type="home" />Home</Button>
                        <Tooltip title={this.props.active_report.title}>
                            <Title level={4} ellipsis className="title">{this.props.active_report.title}</Title>
                        </Tooltip>
                        <div>
                            {action_dropdown}
                            <Button type="warning" className="right-button" onClick={() => {this.handleLogout()}}><Icon type="logout"/>Logout</Button>
                        </div>
                    </HeaderContainerWrapper>
                </Affix>
                <Switch>
                    <Route exact path='/' render={() => <TabContainer active_report={this.props.active_report} setReportDetails={this.props.setReportDetails}></TabContainer>}/>
                    <Route path='/reports/:report_id/:version' render={() => <ReportContainer 
                    reload_toc={this.state.reload_toc} handleReloadTOC={this.handleReloadTOC} handlePriceSheetDownload={this.handlePriceSheetDownload} handleExportGraphs={this.handleExportGraphs}/>} />
                    <Route path='/config/:report_id/:version' render={() => <ReportContainer 
                    reload_toc={this.state.reload_toc} handleReloadTOC={this.handleReloadTOC} handlePriceSheetDownload={this.handlePriceSheetDownload} handleExportGraphs={this.handleExportGraphs}/>} />
                    <Route path='/users/:id' render={() => <UserConfig></UserConfig>}/>
                </Switch>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        active_report: state.REPORTREDUCER.ACTIVE_REPORT
    }
}

const mapDispatchToProps = {
    setReportDetails,
    setSectionDetails,
    logoutRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));