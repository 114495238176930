import React from 'react';
import {Table} from 'antd';

const UserQueryComponent = ({data}) => {
      
      const columns = data.customization_reqs ? [
        {
          title: 'Message',
          dataIndex: 'message',
        },
        {
          title: 'Timestamp',
          render: (elem) => new Date(elem.timestamp).toLocaleString()
        }
      ] : 
      [
        {
            title: 'Message',
            render: (elem) => elem.message || 'N/A'
        },
        {
            title: 'Timestamp',
            render: (elem) => new Date(elem.timestamp).toLocaleString()
        },
        {
            title: 'Time to callback',
            render: (elem) => new Date(elem.timeToCallback).toLocaleString()
        }
      ] 
      
      return <Table style={{marginTop: 20}} bordered rowKeys={'timestamp'} dataSource={data.customization_reqs ? data.customization_reqs : data.request_callback} columns={columns} />;
}

export default UserQueryComponent;
