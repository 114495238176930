import React from 'react';
import { Select, notification, Typography, Steps} from 'antd';
import { fetchReports } from './AddActions';
import { AddContainerWrapper, DetailsWrapper, FormWrapper, MetaWrapper, UploadWrapper } from './AddContainerStyle';
import config from '../../../../config/config';
import MetaFormComponent from './MetaForm/MetaFormContainer';
import TOCUploadComponent from './TOCUpload/TOCUploadFormContainer';
const { Paragraph, Text } = Typography;

const Option = Select.Option;
const Step = Steps.Step;

let options = {
    'global' : [],
    'country' : [],
    'region' : []
};

const steps = [{
    key: 0,
    title: 'Meta Details',
  }, {
    key: 1,
    title: 'TOC Upload',
}];

async function handleFetchReports(value, callback) {

    try {
        let Reports = await fetchReports(value);
        callback(Reports.data);
    } catch (error) {
        console.log(error);
        notification['error']({
            message: 'Error',
            description: 'Error fetching reports',
        });
        callback([]);
    }

} 

class AddReport extends React.Component {

    state = {
        data: [],
        value: undefined,
        current_report: null,
        chosen_options: [],
        meta_body: null,
        current_step: 0,
        fileList: [],
    }

    componentDidMount() {
        for(let key in options) {
            options[key] = config[key].map((elem) => {
                return (<Option key={elem}>{elem}</Option>)
            })
        }
    }

    handleSearch = (value) => {
        handleFetchReports(value, data => this.setState({ data }));
    }

    handleMetaAddition = (meta_body) => {
        this.setState({
            ...this.state,
            meta_body: meta_body
        })
    }

    handleChange = (value) => {
        if(value && !isNaN(value)) {
            let report = this.findReport(parseInt(value));
            this.setState({
                ...this.state,
                current_step: 0,
                fileList: [],
                chosen_options: [],
                current_report: report
            });
        }
        this.setState({ value: value });
    } 

    handleGeographyChange = (value) => {
        this.setState({
            ...this.state,
            chosen_options: options[value]
        });
    }

    findReport = (id) => {
        for(let elem of this.state.data) {
            if(elem.id === id) {
                let temp_report = {
                    id: elem.id,
                    version: null,
                    title: elem.title
                }
                this.props.setReportDetails(temp_report);
                return elem;
            }
        }
        return null;
    }

    handleNextStep = () => {
        const current_step = this.state.current_step + 1;
        this.setState({current_step: current_step});
    }

    handleUpload = (file, fileList) => {
        if(fileList.length > 1) {
            notification['error']({
                message: 'Error',
                description: 'Cannot upload more than one file',
            });
            return false;
        } else {
            this.setState({
                ...this.state,
                fileList: fileList
            })
        }
    }

    render() {

        const options = this.state.data && this.state.data.length ? this.state.data.map(elem => <Option key={elem.id}>{elem.title}</Option>) : [];

        const stepper_content = [
            <MetaWrapper>
                <MetaFormComponent chosen_options={this.state.chosen_options} handleGeographyChange={this.handleGeographyChange} handleNextStep={this.handleNextStep} current_report={this.state.current_report} title={this.props.active_report.title} handleMetaAddition={this.handleMetaAddition}></MetaFormComponent>
            </MetaWrapper>,
            <UploadWrapper>
                <TOCUploadComponent fileList = {this.state.fileList} handleUpload={this.handleUpload} handlePreviousStep={this.handlePreviousStep} meta_body={this.state.meta_body}></TOCUploadComponent>
            </UploadWrapper>
        ]

        const details_pane = this.props.active_report.id 
        ? <div style={{marginTop: '20px', marginLeft: '10px'}}>
            <Paragraph ellipsis> <Text type="secondary" strong>Website Title : </Text> {this.state.current_report ? this.state.current_report.title : ''}</Paragraph>
            <DetailsWrapper>
                <Paragraph ellipsis> <Text type="secondary" strong>Category : </Text> {this.state.current_report ? this.state.current_report.category_name: ''}</Paragraph>
                <Paragraph ellipsis> <Text type="secondary" strong>Subcategory : </Text> {this.state.current_report ? this.state.current_report.sub_category_name: ''}</Paragraph>
            </DetailsWrapper>
            <FormWrapper>
                <Steps current={this.state.current_step}>
                    {steps.map(item => <Step key={item.key} title={item.title} />)}
                </Steps>
            </FormWrapper>
            <div>{stepper_content[this.state.current_step]}</div>
          </div>
          : '';

        return (
            <div>
                <AddContainerWrapper>
                    <Select
                    showSearch
                    value={this.props.active_report.title ? this.state.value : undefined}
                    placeholder= {'Search for a report by it\'s title'}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    allowClear
                    onChange={this.handleChange}
                    onSearch={this.handleSearch}
                    >
                    {options}
                    </Select>
                </AddContainerWrapper>
                {details_pane}
            </div>
        )
    }
}

export default AddReport;