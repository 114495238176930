import React, { Component } from 'react';
import { history } from '../history';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import LoginContainer from './Login/LoginContainer';
import Search from './Search/SearchContainer';

class Routes extends Component {

  render() {
    const IS_AUTH = this.props.login.ISAUTH;
    return (
      <Router history={history}>
        <Switch>
          <Route exact path='/login' render={() => <LoginContainer/>}/>
          <Route isAuth={IS_AUTH} path='/' component={Search} />
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.LOGINREDUCER
  };
}

export default connect(mapStateToProps)(Routes);
