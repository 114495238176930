import React from 'react';
import { Form, Select } from 'antd';
import axios from 'axios';
import config from '../../../../../config/config';

const { Option } = Select;

let timeout;
let currentValue;

class SearchInput extends React.Component {

  fetch = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
  
    const fake = () => {
      let supported = this.props.form.getFieldValue(`type[${this.props.k}]`);
      axios.get(`${config.api.base_url}/graphs/search?search=${value}&supported=${supported === 'supporting' ? 'true' : 'false'}`, 
      {headers: {authorization: localStorage.getItem('token')}})
      .then(d => {
      if (currentValue === value) {
          callback(d.data);
      }
      });
    }
  
    timeout = setTimeout(fake, 300);
  }

  handleSearch = value => {
    this.fetch(value, data => {
      this.props.setData(data);
    });
  };

  handleChange = value => {
    this.props.form.setFieldsValue({
      [`search[${this.props.k}]`] : value
    });
    let supported = this.props.form.getFieldValue(`type[${this.props.k}]`);
    if(supported !== 'supporting') {
      this.props.setGraphIndex(this.props.k, value);
      this.props.setGraphValues(this.props.k, value);
    }
    else {
      let graph = this.props.data.find(item => item._id === value);
      this.props.form.setFieldsValue({
        [`heading[${this.props.k}]`]: graph.heading,
        [`metric[${this.props.k}]`]: graph.labels.metric,
        [`graph_id[${this.props.k}]`]: graph._id ? graph._id : null,
        [`graph_type[${this.props.k}]`]: graph.type,
        [`price[${this.props.k}]`]: graph.price
      });
      this.props.setGraphValues(this.props.k, [graph._id]);
      this.props.setGraphIndex(this.props.k, this.transformGraphLabels(graph.labels));
    }
  };

  transformGraphLabels = (labels) => {
    let graph = [labels.xaxis];
    if(Array.isArray(labels.yaxis) && labels.yaxis.length) {
        for(let item of labels.yaxis) {
            graph.push([item.label, ...item.data]);
        }
    }
    return graph;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let type = this.props.form.getFieldValue(`type[${this.props.k}]`);
    let required = type == 'existing';
    let options = this.props.data.map(d => <Option key={d._id}>{d.heading}</Option>);
    return (
      type == 'existing'?
      <Form.Item label="Graph">
        {getFieldDecorator(`search[${this.props.k}]`, {
          initialValue: null,
          rules: [{ required: required, message: 'This field is required'}]
        })(
          <Select
            showSearch
            mode="multiple"
            placeholder="Select a graph"
            style={{width: '900px'}}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            notFoundContent={null}
          >
            {options}
          </Select>
      )}
      </Form.Item>:
      <Form.Item label="Graph">
        {getFieldDecorator(`search[${this.props.k}]`, {
          initialValue: null,
          rules: [{ required: required, message: 'This field is required'}]
        })(
          <Select
            showSearch
            placeholder="Select a graph"
            style={{width: '300px'}}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            notFoundContent={null}
          >
            {options}
          </Select>
      )}
      </Form.Item>
    );
  }
}

export default SearchInput;