import React from 'react';
import { Select, Input, Typography, InputNumber, Form, Button} from 'antd';

const { Text } = Typography;
const Option = Select.Option;

class MetaFormComponent extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields( (err, values) => {
            if (!err) {
              let form_data = {
                  id: this.props.current_report.id,
                  sub_category_id: this.props.current_report.sub_category_id,
                  category_id: this.props.current_report.category_id,
                  title: values.title,
                  pages: values.pages,
              }
              form_data['geography'] = {[values['geography']] : values.geography2};
              this.props.handleMetaAddition(form_data);
              this.props.handleNextStep();
            }
        });
    }

    handleGeographyChange = (value) => {
        this.props.form.setFieldsValue({
            'geography2': undefined
        })
        this.props.handleGeographyChange(value);
    }

    handlePageInput = (value) => {
        if(!isNaN(value)) {
            return value;
        } else 
        return value.substring(0, value.length - 1);
    }

    render() {
    return (
        <Form onSubmit={this.handleSubmit}>
            <div style={{display: 'flex',}}>
                <Form.Item>
                    <Text>Report Title: </Text>
                </Form.Item>
                <Form.Item style={{marginLeft: '4px', width: '1106px'}}>{this.props.form.getFieldDecorator('title', {
                    initialValue: this.props.title,
                    rules: [{ required: true, message: 'Please enter a report title that is below 250 characters!', whitespace: true, max: 250 }],
                })(
                    <Input placeholder="Enter report title"></Input>
                )}</Form.Item>
            </div>
            <div style={{marginTop: '5px', display: 'flex'}}>
                <Form.Item>
                    <Text>Geography: </Text>
                </Form.Item>
                <Form.Item style={{marginLeft: '5px'}}>
                    {this.props.form.getFieldDecorator('geography', {
                        rules: [{ required: true, message: 'Please enter a geography tag!', whitespace: true }],
                    })(
                        <Select placeholder="Geography" onChange={(value) => {this.handleGeographyChange(value)}}>
                            <Option key="global">Global</Option>
                            <Option key="region">Region</Option>
                            <Option key="country">Country</Option>
                        </Select>
                    )}
                </Form.Item>    
                <Form.Item style={{marginLeft: '5px'}}>
                    {this.props.form.getFieldDecorator('geography2', {
                        rules: [{ required: true, message: 'Please enter a geography tag!', whitespace: true }],
                    })(
                        <Select placeholder="Please enter a country/region/global">
                            {this.props.chosen_options}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item style={{marginLeft: '140px'}}>
                    <Text>Pages:</Text>
                </Form.Item>
                <Form.Item style={{marginLeft: '5px'}}>
                    {this.props.form.getFieldDecorator('pages', {
                        rules: [{ required: true, message: 'Please enter the number of pages!'}],
                    })(
                        <InputNumber formatter={(value) => this.handlePageInput(value)} min={1}></InputNumber>
                    )}
                </Form.Item>
            </div>
            <Form.Item style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button type="primary" htmlType="submit">Next</Button>
            </Form.Item>
        </Form>
        )
    }
}

export default Form.create({ name: 'meta_form' })(MetaFormComponent);