import axios from 'axios';
import config from '../../../../config/config';

export const fetchReports = (search_query) => {
    return axios.get(config.api.mordor_url + config.api.search + search_query, {headers: {authorization: localStorage.getItem('token')}});
}

export const publishReport = (report_id, version) => {
    return axios.get(config.api.base_url + config.api.publish_report + '?id=' + report_id + '&version=' + version, {headers: {authorization: localStorage.getItem('token')}});
}

export const deleteReport = (report_id, version) => {
    return axios.delete(config.api.base_url + config.api.delete_report + '?id=' + report_id + '&version=' + version, {headers: {authorization: localStorage.getItem('token')}});
}

export const downloadPriceSheet = (report_id, version) => {
    return axios.get(config.api.base_url + config.api.download_price_sheet + '?id=' + report_id + '&version=' + version, {headers: {authorization: localStorage.getItem('token')}});
}

export const uploadPriceSheet = (file) => {
    let data = new FormData();
    data.append('attachment', file.file);
    return axios.post(config.api.base_url + config.api.upload_price_sheet, data, {headers: {authorization: localStorage.getItem('token')}});
}

export const uploadHeading = (file, report_id) => {
    let data = new FormData();
    data.append('attachment', file.file);
    return axios.post(config.api.base_url + config.api.upload_heading + '?report_id=' + report_id, data, {headers: {authorization: localStorage.getItem('token')}});
}

export const exportGraphs = (report_id, version) => {
    return axios.get(`${config.api.base_url}${config.api.export_graphs}?report_id=${report_id}&version=${version}`, {headers: {authorization: localStorage.getItem('token')}});
}

export const uploadPDF = (file, report_id, version, callback) => {
    let data = new FormData();
    data.append('attachment', file.file);
    axios.post(config.api.base_url + config.api.upload_pdf + '?id=' + report_id
    + '&version=' + version, data, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        if(response.status === 200) {
            callback(null);
        }
        else
            callback(new Error('Non 2XX status reached on PDF upload'));
    }).catch(error => {
        callback(error);
    })
}

export const uploadTOC = (file, report_id, version, callback) => {
    let data = new FormData();
    data.append('attachment', file.file);
    axios.post(config.api.base_url + config.api.toc_upload + '?id=' + report_id
    + '&version=' + version, data, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        if(response.status === 200) {
            callback(null);
        }
        else
            callback(new Error('Non 2XX status reached on CSV upload'));
    }).catch(error => {
        callback(error);
    })
}