import axios from 'axios';
import config from '../../../config/config';
import { notification } from 'antd';

export const setReports = (data) => ({
    type: 'SET_REPORTS',
    data
});

export const getUserDetails = (id, callback) => {
    console.log(config.api.base_url, config.api.user_details, id, )
    axios.get(`${config.api.base_url + config.api.user_details}/${id}`, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
        notification.error({
            message: 'Error!',
            description: 'Unable to fetch report list!'
        })
    });
}

export const getReports = (id) => {
    return function(dispatch) {
        dispatch(setReports({loading: true, list: []}));
        axios.get(`${config.api.base_url + config.api.list_reports}/${id}`, {headers: {authorization: localStorage.getItem('token')}})
        .then(response => {
            console.log(response.data)
            dispatch(setReports({loading: false, list: response.data}));
        }).catch(error => {
            console.log(error);
            dispatch(setReports({loading: false, list: []}));
            notification.error({
                message: 'Error!',
                description: 'Unable to fetch report list!'
            })
        });
    }
}

export const getUserCustomizations = (id, callback) => {
    axios.get(`${config.api.base_url + config.api.user_customizations}/${id}`, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
        notification.error({
            message: 'Error!',
            description: 'Unable to fetch report list!'
        })
    });
}

export const getUserCallbacks = (id, callback) => {
    axios.get(`${config.api.base_url + config.api.user_callbacks}/${id}`, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
        notification.error({
            message: 'Error!',
            description: 'Unable to fetch report list!'
        })
    });
}