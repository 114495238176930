const api = {
    // External URLs to query
    base_url_prod: 'https://api-micms.mordorintelligence.com',
    base_url_dev: 'http://localhost:7001',
    base_url_test: 'https://beta-mi.mordorintelligence.com',
    mordor_url: 'https://mordorintelligence.com:81',

    // Extensions on the URLs
    search: '/api/reports/search?q=',
    edit_reports: '/reports/get-report-list?status=ALL',
    preview_reports: '/reports/get-report-list?status=PUBLISHED',
    report_preview: '/reports/preview-report',
    section_preview: '/reports/preview-section',
    toc_upload: '/reports/toc-upload',
    add_meta: '/reports/add-meta',
    prev_image: '/reports/show-last-upload',
    delete_report: '/reports/delete-report',
    delete_section: '/reports/delete-section',
    insert_section: '/reports/add-section',
    report_list: '/reports/get-report-list',
    report_details: '/reports/get-report-details',
    toc_tree: '/reports/get-toc-tree',
    add_section: '/section/add-content',
    get_section: '/section/get-content',
    section_title: '/reports/update-section-title',
    upload_img: '/reports/upload_img',
    upload_pdf: '/reports/upload_pdf',
    download_price_sheet: '/reports/export-toc',
    upload_price_sheet: '/reports/updatePriceUsingSectionId',
    upload_heading: '/graphs/upload-graph',
    export_graphs: '/graphs/export',
    default_config: '/reports/default-config',
    publish_report: '/reports/publish-report',
    add_sample_data: '/section/update-sample-data',
    get_cms_users: '/cms-users/listCmsUser',
    get_platform_users: '/cms-users/getPlatformUsers',
    toggle_status: '/cms-users/toggleStatus',
    update_wallet: '/cms-users/update-wallet',
    list_reports: '/cms-users/list-reports',
    add_supporting_graph: '/graphs/add',
    update_supporting_graph: '/graphs/update',
    user_details: '/cms-users/platform-user-details',
    user_config: '/cms-users/update-config',
    user_customizations: '/cms-users/list-customization',
    user_callbacks: '/cms-users/list-callbacks'
}

export default api;