import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Icon, notification, Tooltip, Popconfirm, Input, Dropdown, Modal } from 'antd';
import { getTocList, updateSectionTitle, insertSection } from './TocContainerActions';

import { TocWrapper } from './TocContainer.style'
import { previewSection } from '../Content/ContentContainerActions';

const SubMenu = Menu.SubMenu;
const confirm = Modal.confirm;

class TocContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            toc: [],
            list: [],
            selected_menus: [],
            selected_items: []
        }
    }

    componentDidMount() {
        this.titleRef = React.createRef();
        let token = localStorage.getItem('token');
        let id = this.props.match.params.report_id;
        let version = this.props.match.params.version;
        getTocList(token, id, version, this.setTocList);
    }

    componentDidUpdate(prevProps) {
        if(this.props.reload_toc) {
            let token = localStorage.getItem('token');
            let id = this.props.match.params.report_id;
            let version = this.props.match.params.version;
            getTocList(token, id, version, this.setTocList);
            this.props.handleReloadTOC(false);
        }
        if(this.props.move_flag) {
            this.getNextMenuItem(this.props.toc_location, true);
        }
    }

    setTocList = (toc) => {
        toc = this.addMenuItemForSubmenus(toc);
        this.recalculateLocations(toc, 0, '0');
        let list = this.populateToc(toc);
        this.setState({
            ...this.state,
            list: toc,
            toc: list
        });
        this.getDefaultSubMenu(toc[0], []);
        this.getDefaultItem(toc[0]);
    }

    showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure delete this section?',
            content: 'This is an irreversible process',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk : () => {
                this.props.handleDeleteSection();
            },
            onCancel () {
            },
        });
    }

    handlePreviewTopSection = (section_id) => {
        previewSection(section_id, this.props.match.params.report_id, this.props.match.params.version, true, (response) => {
            if(response.htmlString) {
                let new_window = window.open('');
                new_window.document.body.innerHTML = response.htmlString;
            } else {
                notification.error({
                    message: 'Error',
                    description: 'No valid content to preview'
                });
            }
        })
    }

    addMenuItemForSubmenus = (toc, depth=0) => {
        let tempToc = JSON.parse(JSON.stringify(toc));
        for(let index in toc) {
            if(toc[index].list && toc[index].list.length) {
                tempToc[index].list = this.addMenuItemForSubmenus(toc[index].list, depth+1);
                let tempObj = {title: toc[index].title, section_id: toc[index].section_id}
                tempToc[index].list = [tempObj].concat(tempToc[index].list);
            }
        }
        return tempToc;
    }

    recalculateLocations = (topItem, index, location) => {
        if(!location || index > topItem.length - 1)
            return;
        let tempLocation = location;
        for(let i = index; i < topItem.length; i++) {
            topItem[i].location = tempLocation;
            if(topItem[i].list) {
                this.recalculateLocations(topItem[i].list, 0, tempLocation + '_0');
            }
            let locationArray = tempLocation.split('_');
            tempLocation = tempLocation.substring(0, tempLocation.lastIndexOf('_') + 1);
            tempLocation += parseInt(locationArray[locationArray.length - 1]) + 1;
        }
    }

    populateToc = (list) => {
        if(Array.isArray(list)) {
            return list.map(item => {
                
                const menu = <Menu>
                                <Menu.Item onClick={(e) => e.domEvent.stopPropagation()}>
                                    <Popconfirm title={<Input onClick={(e) => e.stopPropagation()} ref={this.titleRef}/>} icon={null} onConfirm={() => this.updateSectionTitle(item)} >
                                        <Icon onClick={(e) => e.stopPropagation()} type='edit' className='toc-edit-icon' /> Edit Title
                                    </Popconfirm>
                                </Menu.Item>
                                <Menu.Item onClick={(e) => e.domEvent.stopPropagation()}>
                                    <Popconfirm title={<Input onClick={(e) => e.stopPropagation()} ref={this.titleRef}/>} icon={null} onConfirm={() => this.addSectionBelow(item)} >
                                        <Icon onClick={(e) => e.stopPropagation()} type='plus' className='toc-edit-icon' /> Add Section Below
                                    </Popconfirm>
                                </Menu.Item>
                                <Menu.Item onClick={(e) => e.domEvent.stopPropagation()}>
                                    <Popconfirm title={<Input onClick={(e) => e.stopPropagation()} ref={this.titleRef}/>} icon={null} onConfirm={() => this.addSectionUnder(item)} >
                                        <Icon onClick={(e) => e.stopPropagation()} type='enter' className='toc-edit-icon' /> Add Section Inside
                                    </Popconfirm>
                                </Menu.Item>
                                <Menu.Item onClick={() => this.showDeleteConfirm()}>
                                    <Icon type='delete' className='toc-edit-icon' style={{marginRight: 0}}/> Delete section
                                </Menu.Item>
                            </Menu>;

                const top_section_boolean = !isNaN(item.location) || (item.location.split('_').length === 2 && item.location.split('_')[1] === '0');
                const title = top_section_boolean ? <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Tooltip title={item.title} placement={"right"}><p style={{overflow: 'hidden', textOverflow: 'ellipsis', margin: '0'}}>{item.title}</p></Tooltip>
                </div> : item.title;
                if(Array.isArray(item.list) && item.list.length) {
                    return (
                        <SubMenu key={item.location} onTitleClick={this.toggleSubmenu} section_id={item.section_id} title={title}>
                            {this.populateToc(item.list)}
                        </SubMenu>
                    )
                } else {
                    return <Menu.Item key={item.location} section_id={item.section_id} title={item.title} >
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{overflow: 'hidden', textOverflow: 'ellipsis', margin: 0}}>
                            {top_section_boolean && item.list? title: item.title}
                        </div>
                        {!this.props.config ? <Dropdown overlay={menu} trigger={['click']}>
                            <Icon type="more" onClick={(e) => e.stopPropagation()} style={{fontSize: 20, marginRight: 0}}/>
                        </Dropdown> : null}
                    </div>
                    </Menu.Item>;
                }
            })
        }
    }

    updateSectionTitle = (item) => {
        if(!this.titleRef.current.state.value)
            return;
        let token = localStorage.getItem('token');
        let id = this.props.match.params.report_id;
        let version = this.props.match.params.version;
        updateSectionTitle(token, id, version, item.section_id, this.titleRef.current.state.value, this.setTocList);
        this.titleRef.current.state.value = '';
    }

    addSectionUnder = (item) => {
        let token = localStorage.getItem('token');
        let id = this.props.match.params.report_id;
        let version = this.props.match.params.version;
        insertSection(token, id, version, item.section_id, this.titleRef.current.state.value, 'child', (err) => {
            if(!err){
                getTocList(token, id, version, this.setTocList);
            }
        })
    }

    addSectionBelow = (item) => {
        let token = localStorage.getItem('token');
        let id = this.props.match.params.report_id;
        let version = this.props.match.params.version;
        insertSection(token, id, version, item.section_id, this.titleRef.current.state.value, 'sibling', (err) => {
            if(!err){
                getTocList(token, id, version, this.setTocList);
            }
        })
    }

    toggleSubmenu = ({event, key}) => {
        if(this.state.selected_menus.indexOf(key) !== -1) {
            this.setState({
                ...this.state,
                selected_menus: this.state.selected_menus.filter(menu => menu !== key)
            });
        } else {
            let new_menus = this.state.selected_menus.slice();
            new_menus.push(key);
            this.setState({...this.state, selected_menus: new_menus});
        }
    }

    menuSelect = (e) => {
        this.props.set_details(e.item.props.title, e.key, e.item.props.section_id, false, true);
        this.setState({...this.state, selected_items: [e.key]});
    }

    getDefaultSubMenu = (list, open_menus) => {
        if(!list)
            return [];
        if(Array.isArray(list.list) && list.list.length) {
            open_menus.push(list.location);
            return this.getDefaultSubMenu(list.list, open_menus);
        }
        this.setState({...this.state, selected_menus: open_menus});
    }

    getDefaultItem = (list) => {
        if(!list)
            return [];
        if(Array.isArray(list.list) && list.list.length) {
            return this.getDefaultItem(list.list);
        }
        if(list && list[0] && Array.isArray(list)) {
            this.props.set_details(list[0].title, list[0].location, list[0].section_id);
            this.setState({...this.state, selected_items: [list[0].location]});
        } else if (list && list.title && list.location && list.section_id) {
            this.props.set_details(list.title, list.location, list.section_id);
            this.setState({...this.state, selected_items: [list.location]});
        }
    }

    getNextMenuItem = (location, move_flag) => {
        let location_array = location.split('_').map(Number);
        let next_location = location.substring(0, location.lastIndexOf('_') + 1);
        next_location += location_array[location_array.length - 1] + 1;
        let item = this.getItemFromLocation(next_location);
        if(item && item.section_id) {
            this.props.set_details(item.title, item.location, item.section_id, move_flag);
            this.setState({...this.state, selected_items: [item.location]});
        } else {
            this.props.setMoveFlag(false);
        }
    }

    getItemFromLocation = (location) => {
        if(!location)
            return null;
        let location_array = location.split('_');
        let topItem = this.state.list[location_array[0]];
        for(let i = 1; i < location_array.length; i++) {
            topItem = topItem.list[location_array[i]];
        }
        return topItem;
    }

    render () {
        return (
            <TocWrapper>
                <Menu
                onClick={this.menuSelect}
                selectedKeys={this.state.selected_items}
                openKeys={this.state.selected_menus}
                mode="inline">
                    {this.state.toc}
                </Menu>
            </TocWrapper>
        );
    }
};

export default withRouter(TocContainer);