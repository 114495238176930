import styled from 'styled-components';

export const UserConfigComponentWrapper = styled.div`
    padding: 50px;
    .user-details {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .label {
        margin-right: 5px;
        font-weight: bold;
    }
`