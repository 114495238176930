const initialState = {
    USERS: { loading: false, list: []},
    REPORTS: { loading: false, list: []},
    CALLBACK_LIST: { loading: false, list: []},
    CUSTOMIZED_LIST: { loading: false, list: []}
}

export const USERREDUCER = (state = initialState, action) => {
    switch(action.type) {
        case 'SET_USERS':
            return {
                ...state,
                USERS: action.data
            }
        case 'SET_REPORTS':
            return {
                ...state,
                REPORTS: action.data
            }
        case 'SET_CALLBACK_LIST':
            return {
                ...state,
                CALLBACK_LIST: action.data
            }
        case 'SET_CUSTOMIZED_LIST':
            return {
                ...state,
                CALLBACK_LIST: action.data
            }
        case 'RESET_USER_REUCER':
            return initialState;
        default:
            return state;
    }
}