import axios from 'axios';
import { history } from '../../history';
import config from '../../config/config';
import { notification } from 'antd';
import { signIn, signOut } from './MsLoginConfig';

export const loginAttempt = () => ({
    type: 'LOGIN_REQUEST'
});

export const loginSuccess = (data) => ({
    type: 'LOGIN_SUCCESS',
    data
});

export const loginFailure = () => ({
    type: 'LOGIN_FAILURE'
});

export const logoutSuccess = () => ({
    type: 'LOGOUT_SUCCESS'
});

export const logoutFailure = () => ({
    type: 'LOGOUT_SUCCESS'
});

export const resetAuthReducer = () => ({
    type: 'RESET_AUTH_REDUCER'
});

export const loginRequest = (token) => {
    return function(dispatch) {
        signIn(dispatch);
    }
}

export const logoutRequest = (token) => {
	return function(dispatch) {
        let headers = {'authorization' : token};
		axios.get(config.api.base_url + '/logout', {headers: headers})
		.then(response => {
            dispatch({type: 'USER_LOGOUT'});
            signOut();
		})
		.catch((error) => {
			console.error(error);
			dispatch(logoutFailure());
		})
	}
};