import React from 'react';
import { history } from '../../../../history';
import { Select, notification } from 'antd';
import { fetchReports } from './EditActions';
import { EditContainerWrapper } from './EditContainerStyle';

const Option = Select.Option;

class EditReport extends React.Component {

    state = {
        data: [],
        options: [],
        value: undefined,
    }

    componentDidMount = async () => {
        try {
            let response = await fetchReports(this.props.mode);
            this.setState({...this.state, data: response.data, options: response.data});
        } catch (error) {
            console.log(error);
            notification['error']({
                message: 'Error',
                description: 'Error fetching reports',
            });
        }
        
    }
    
    handleChange = (value) => {
        this.setState({ value: value });
        let mode = this.props.mode == 'published' ? '/config/' : '/reports/';
        let url = mode + value;
        history.push(url);
    }

    filterReports = (query) => {
        if(query) {
            this.setState({
                ...this.state,
                options: this.state.data.filter(elem => {
                    return elem.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
                })
            })
        } else {
            this.setState({
                ...this.state,
                options: this.state.data
            })
        }
    }

    render() {
        const options = this.state.options && this.state.options.length ? this.state.options.map(elem => <Option key={elem.id + '/' + elem.version}>{elem.title + ' v' + elem.version}</Option>) : [];

        return (
            <div>
            <EditContainerWrapper>
                <Select
                showSearch
                value={this.state.value ? this.state.value : undefined}
                placeholder= 'Enter a search query'
                defaultActiveFirstOption={false}
                allowClear
                onSearch={this.filterReports}
                showArrow={false}
                filterOption={false}
                onChange={this.handleChange}
                notFoundContent={null}
                >
                {options}
                </Select>
            </EditContainerWrapper>
            </div>
        )
    }
}

export default EditReport;