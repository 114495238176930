import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Switch, InputNumber, Button } from 'antd';
import { getUsers, toggleStatus, updateWallet } from './UserActions';
import { UserContainerWrapper } from './UserContainerStyle';

class UserContainer extends Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
              title: 'Email',
              render: (elem) => {
                return <Link to={{pathname: '/users/' + elem._id }}>{elem.email}</Link>
              }
            },
            {
              title: 'Activate',
              render: (elem) => <Switch
                onChange={() => {toggleStatus(elem._id, () => {this.props.getUsers()})}}
                checkedChildren="Deactivate"
                unCheckedChildren="Activate"
                defaultChecked={elem.active}
              />
            },
            {
              title: 'Credits',
              render: (elem) =>  {
                let ref = React.createRef();
                return (
                    <div>
                        <InputNumber
                            style={{marginRight: 20}}
                            defaultValue={elem.credit}
                            formatter={value => `${value}`.replace(/[A-Za-z]*/g, '')}
                            ref={ref}
                        />
                        <Button type="primary" onClick={() => {updateWallet(elem._id, ref.current.inputNumberRef.currentValue, () => {this.props.getUsers()})}}>Save</Button>
                    </div>
                )
              }
            },
        ];
    }
    
    componentDidMount() {
        this.props.getUsers();
    }

    render() {
        return (
            <UserContainerWrapper>
                <Table bordered rowKey={'_id'} className="user-table"
                columns={this.columns} dataSource={this.props.users.list} />,
            </UserContainerWrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        users: state.USERREDUCER.USERS
    }
}

const mapDispatchToProps = {
    getUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);