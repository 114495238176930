import { combineReducers } from 'redux';
import { LOGINREDUCER } from '../components/Login/LoginReducer';
import { REPORTREDUCER } from '../components/Report/ReportReducer';
import { USERREDUCER } from '../components/Users/UserReducer';

const appReducer = combineReducers({
  LOGINREDUCER,
  REPORTREDUCER,
  USERREDUCER
});

const rootReducer = (state, action) => {

  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }
  
  return appReducer(state, action)
}

export default rootReducer;