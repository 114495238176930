import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TocContainer from './Toc/TocContainer';
import ContentContainer from './Content/ContentContainer';
import { getReportDetails, deleteSection, setSectionDetails, setTOCDetails, setSectionMode } from './ReportActions';
import { getSection } from './Content/Section/SectionContainerActions';

import { ReportWrapper } from './ReportContainer.style';

let config = false;

class ReportContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            section_id: '',
            location: '',
            move_flag: false,
            menu_select: false,
        }
        config = this.props.match.path.indexOf('config') != -1;
    }

    componentDidMount = () => {
        this.props.handlePriceSheetDownload(this.props.match.params.report_id, this.props.match.params.version);
        this.props.handleExportGraphs(this.props.match.params.report_id, this.props.match.params.version);
        this.props.getReportDetails(
        localStorage.getItem('token'), 
        this.props.match.params.report_id,
        this.props.match.params.version);
    }

    setMoveFlag = (value) => {
        this.setState({
            ...this.state,
            move_flag: value
        })
    }

    setMenuSelect = (value) => {
        this.setState({
            ...this.state,
            menu_select: value
        }, () => {
            this.setState({
                ...this.state,
                menu_select: value
            })
        })
    }

    handleDeleteSection = () => {
        let id = this.props.match.params.report_id;
        let version = this.props.match.params.version;
        let section_id = this.state.section_id;
        let token = localStorage.getItem('token');
        deleteSection(token, id, version, section_id, () => {
            this.props.handleReloadTOC(true);
        })
    }

    setDetails = (title, location, section_id, set_move_flag, menu_select_flag) => {
        this.setState(state => {
            return {
                ...this.state,
                title: title,
                location: location,
                section_id: section_id,
                move_flag: set_move_flag ? false : state.move_flag,
                menu_select: menu_select_flag
            }
        });
        let toc_details = {
            ...this.props.active_toc,
            location: location,
            move_flag: set_move_flag ? false : true,
            menu_select: menu_select_flag
        };
        let section_details = {
            section_id: section_id,
            title: title
        };
        this.props.setTOCDetails(toc_details);
        this.props.setSectionDetails(section_details);
    }

    render() {
        return (
            <ReportWrapper>
                <TocContainer set_details={this.setDetails} setMenuSelect={this.setMenuSelect}
                toc_location={this.state.location} setMoveFlag={this.setMoveFlag} move_flag={this.state.move_flag} 
                reload_toc={this.props.reload_toc} handleReloadTOC={this.props.handleReloadTOC} handleDeleteSection={this.handleDeleteSection}
                config={config}/>
                <ContentContainer title={this.props.active_section.title} section_id={this.props.active_section.section_id} setMenuSelect={this.setMenuSelect} 
                menu_select={this.state.menu_select} toc_location={this.state.location} setMoveFlag={this.setMoveFlag} move_flag={this.state.move_flag} 
                getSection={this.props.getSection} content={this.props.active_section.content} handleDeleteSection={this.handleDeleteSection} 
                setSectionMode={this.props.setSectionMode} config={config}/>
            </ReportWrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        active_report: state.REPORTREDUCER.ACTIVE_REPORT,
        active_toc: state.REPORTREDUCER.ACTIVE_TOC,
        active_section: state.REPORTREDUCER.ACTIVE_SECTION
    }
}

const mapDispatchToProps = {
    getReportDetails,
    setSectionDetails,
    setTOCDetails,
    getSection,
    setSectionMode
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportContainer));