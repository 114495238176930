import React from 'react';
import { withRouter } from 'react-router-dom';
import { uploadImage } from '../../ContentContainerActions';
import { getPrevImage } from '../SectionContainerActions';
import config from '../../../../../config/config';
import { HotTable } from '@handsontable-pro/react';
import { Draggable } from 'react-beautiful-dnd';
import { Input, Select, Form, Upload, Button, Icon, Checkbox, Radio, notification, InputNumber, Tag } from 'antd';
import GraphSearch from './graphSearch';

const Option = Select.Option;
const { TextArea } = Input;
const section_elements = ['Paragraph', 'Section-heading', 'List', 'Image', 'Heading', 'Heading2', 'Heading3', 'Table', 'Graph'];
const config_elements = ['Masked', 'Sample', 'Actual'];
const config_elements_graph = ['Masked', 'Actual'];
const config_list = ['Graph', 'Image', 'Table'];

let hotTableRefs = [];

class SectionFormItems extends React.Component {

    graphTypeChange = (value, k) => {
        this.props.form.setFieldsValue({
            [`search[${k}]`] : undefined 
        })
        this.props.setData([]);
        if(value === 'supporting') {
            this.props.setGraphIndex(k, []);
        } else {
            this.props.setGraphIndex(k, '');
        }
    }

    componentDidMount () {
        let keys = this.props.form.getFieldValue('keys');
        let collapsed = keys.map((elem) => {return false});
        this.props.handleCollapsedSections(collapsed);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.section_id !== this.props.section_id) {
            this.props.emptyFileList();
        }
    }

    addTableRef = (index) => {
        let tempRef = React.createRef();
        hotTableRefs[index] = tempRef;
    }

    elementChange = (index, value, k) => {
        this.props.elements[index] = value.target.value;
        this.props.removeImage(k);
        this.props.setFileList([], k);
        this.props.form.setFieldsValue({
            [`values[${k}]`]: ''
        })
    }

    tabInterceptor = (e, k, index) => {
        if(e.keyCode === 9) {
            e.preventDefault();
            let start = this.props.input_refs[index].current.textAreaRef.selectionStart;
            let end = this.props.input_refs[index].current.textAreaRef.selectionEnd;
            let val = e.target.value;
            let new_value = val.substring(0, start) + '\t' + val.substring(end);
            this.props.form.setFieldsValue({
                [`values[${k}]`]: new_value
            }, () => {
                this.props.input_refs[index].current.textAreaRef.selectionStart = this.props.input_refs[index].current.textAreaRef.selectionEnd = start + 1;
            })
        }
        if(e.keyCode === 13 && !e.shiftKey) {
            const { getFieldValue } = this.props.form;
            if(k === getFieldValue('elements').length - 1) {
                e.preventDefault();
                this.props.add_item();
            }
        }
    }

    getDynamicFormItems = (index, k, display_none) => {
        let config = this.props.form.getFieldValue('config');
        switch(this.props.elements[index]) {
            case 'Heading':
            case 'Heading2':
            case 'Heading3':
            case 'Paragraph':
            case 'List':
            case 'Section-heading':
                return this.getGeneralItems(k, index, this.props.elements[index], display_none, config ? config[index] : 'Sample');
            case 'Image':
                return this.getImageItems(k, index, display_none, config ? config[index] : 'Sample');
            case 'Table':
                return this.getTableItems(index, k, display_none, config ? config[index] : 'Sample');
            case 'Graph':
                return this.getGraphItems(index, k, display_none, config ? config[index] : 'Sample');
            default:
                break;
        }
    }

    getDynamicFormItemsCollapsed = (index, k) => {
        let items = this.props.form.getFieldsValue();
        let headings = items['heading'];
        let search = items['search'];
        let values = items['values'];
        let sources = items['source'];
        let color = (headings && headings[k]) || ( values && values[k]) || (search && search[k]) || (sources && sources[k]) ? '' : '#f5222d';
        return (<span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '70%', color: color}}>
            {headings && headings[k] ? headings[k]: values && values[k] ? values[k] : search && search[k] ? 'Graph present' : sources && sources[k] ? 'Content Present' : 'Please enter content!'}
        </span>)
    }

    handleSectionCollapse = (index) => {
        let collapsed = this.props.collapsed;
        if(collapsed[index]) {
            collapsed[index] = !collapsed[index]
        } else {
            collapsed[index] = true;
        }
        this.props.handleCollapsedSections(collapsed);
    }

    changeUpload = (k) => {
        this.props.removeImage(k);
    }

    getGeneralItems = (k, index, item, display_none, conf) => {
        const { getFieldDecorator } = this.props.form;
        let rules = {
            rules: [this.props.elements[k] === 'Table' ? 
            {whitespace: true} :
            {message: 'This item is required!'}, {required: true, message: 'This item is required!'}]
        }
        if(!this.props.input_refs[index])
            this.props.input_refs[index] = React.createRef();
        return (
            !this.props.config ? 
            <Form.Item label={'Value'} className="element-value" style={display_none || this.props.config && conf != 'Actual' ? {display: 'none'} : {}}>
                {getFieldDecorator(`values[${k}]`, rules)(
                    <TextArea maxLength={'50000'} id="enable-tab"
                    rows={item === 'List' || item === 'Paragraph' ? 9 : 3}
                    ref={this.props.input_refs[index]} className={`table${k}`}
                    onKeyDown={(e) => this.tabInterceptor(e, k, index)} />
                )}
            </Form.Item> :
            [<Form.Item label={this.props.config ? this.props.elements[index] : 'Value'} className="element-value" style={display_none || conf == 'Sample' ? {display: 'none'} : {}}>
                {getFieldDecorator(`values[${k}]`, rules)(
                    <TextArea maxLength={'50000'} id="enable-tab" disabled={true}
                    rows={item === 'List' || item === 'Paragraph' ? 9 : 3}
                    ref={this.props.input_refs[index]} className={`table${k}`}
                    onKeyDown={(e) => this.tabInterceptor(e, k, index)} />
                )}
            </Form.Item>,
            <Form.Item label={this.props.elements[index]} className="element-value" style={display_none || conf != 'Sample' ? {display: 'none'} : {}}>
            {getFieldDecorator(`samples[${k}]`, rules)(
                <TextArea maxLength={'50000'} id="enable-tab" disabled={conf != 'Sample' || this.props.user_config}
                rows={item === 'List' || item === 'Paragraph' ? 9 : 3}
                ref={this.props.input_refs[index]} className={`table${k}`}
                onKeyDown={(e) => this.tabInterceptor(e, k, index)} />
            )}
            </Form.Item>]
        )
    }

    customRequest = (file, k) => {
        this.props.changeUploadProgress(true);
        this.props.setImageIndex(k, file.file.name);
        uploadImage(file, this.props.match.params.report_id, this.props.match.params.version, this.props.section_id, this.props.images, k, (err, url) => {
            this.props.changeUploadProgress(false);
            if(err)
                this.props.removeImage(k);
            else {
                getPrevImage(this.props.match.params.report_id, this.props.match.params.version, (url) => {
                    let filename = url.file_name;
                    filename = filename.split('_');
                    if(filename && filename.length)
                        filename.splice(0, 1);
                    filename = filename.join('_');
                    this.props.setPrevImage(filename);
                })
            }
        });
    }
    
    getImageItems = (k, index, display_none, conf) => {
        const { getFieldDecorator } = this.props.form;
        const validator = (rules, values, cb) => {
            this.props.images[k] ? cb(): cb('Please provide a valid image!')
        };
        return (
            !this.props.config ? 
            <div className="row-wrap image-container" style={display_none ? {display: 'none'} : {}}>
                <Form.Item label="Heading" className="image-item">
                    {getFieldDecorator(`heading[${k}]`)(
                        <Input disabled={this.props.config && conf != 'Sample' } placeholder="Heading"/>
                    )}
                </Form.Item>
                <Form.Item label="Source" className="image-item">
                    {getFieldDecorator(`source[${k}]`, {
                    rules: [{ required: true, message: 'This item is required!', whitespace: true}],
                    initialValue: "Mordor Intelligence"
                })(
                        <Input disabled={this.props.config && conf != 'Sample' } placeholder="Source"/>
                    )}
                </Form.Item>
                <Form.Item label="Size" className="image-item">
                    {getFieldDecorator(`size[${k}]`, {
                        initialValue: "Medium"
                    })(
                        <Select placeholder="Image size" disabled={this.props.config && conf != 'Sample' }>
                            <Option key="Low">Low</Option>
                            <Option key="Medium">Medium</Option>
                            <Option key="High">High</Option>
                        </Select>
                    )}
                </Form.Item>
                <div className="image-wrapper">
                    <Form.Item className="image-upload">
                        {getFieldDecorator(`image[${k}]`, {
                            rules: [{validator: validator, whitespace: true}]
                    })(
                        <Upload {...{
                            name: 'files',
                            accept: 'image/gif, image/jpeg, image/svg+xml, image/png',
                            customRequest: (file) => this.customRequest(file, k),
                            onChange: (file) => {this.props.setFileList([file.file], k)},
                        }} fileList={this.props.file_list[k]} disabled={this.props.config && conf != 'Sample' } showUploadList={{showPreviewIcon: true, showRemoveIcon: false}}>
                            <Button disabled={this.props.config && conf != 'Sample' }>
                            <Icon type="upload" /> Click to Upload
                            </Button>
                        </Upload>
                    )}
                    </Form.Item>
                    {this.props.images[k] ? (<div className="image-tag">
                        <img src={`${config.api.base_url}/uploads/${this.props.match.params.report_id}/${this.props.match.params.version}/${this.props.images[k]}`} alt={'Preview loading...'} style={{height: '200px', width: 'auto', marginLeft: '20px'}} />
                    </div>) : 
                    (<div className="prev-image">
                        <b>Last uploaded image : </b>
                        <span>{this.props.images[this.getPrevKValue(k)] ? this.props.images[this.getPrevKValue(k)] : this.props.prev_image ? this.props.prev_image : ''}</span>
                    </div>)}
                </div>
            </div>
            :
            <div className="row-wrap image-container" style={display_none ? {display: 'none'} : {}}>
                <Form.Item label="Heading" className="image-item" style={conf == 'Sample' ? {display: 'none'} : {}}>
                    {getFieldDecorator(`heading[${k}]`)(
                        <Input disabled={true} placeholder="Heading"/>
                    )}
                </Form.Item>
                <Form.Item label="Heading" className="image-item" style={conf != 'Sample' ? {display: 'none'} : {}}>
                    {getFieldDecorator(`sample_heading[${k}]`, {
                    rules: [{ whitespace: true}],
                })(
                        <Input placeholder="Heading"/>
                    )}
                </Form.Item>
                <Form.Item label="Source" className="image-item" style={conf == 'Sample' ? {display: 'none'} : {}}>
                    {getFieldDecorator(`source[${k}]`, {
                    rules: [{whitespace: true}],
                    initialValue: "Mordor Intelligence"
                })(
                        <Input disabled={true} placeholder="Source"/>
                    )}
                </Form.Item>
                <Form.Item label="Source" className="image-item" style={conf != 'Sample' ? {display: 'none'} : {}}>
                    {getFieldDecorator(`sample_source[${k}]`, {
                    rules: [{whitespace: true}],
                    initialValue: "Mordor Intelligence"
                })(
                        <Input placeholder="Source"/>
                    )}
                </Form.Item>
                <Form.Item label="Size" className="image-item" style={conf == 'Sample' ? {display: 'none'} : {}}>
                    {getFieldDecorator(`size[${k}]`, {
                        initialValue: "Medium"
                    })(
                        <Select placeholder="Image size" disabled={true}>
                            <Option key="Low">Low</Option>
                            <Option key="Medium">Medium</Option>
                            <Option key="High">High</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Size" className="image-item" style={conf != 'Sample' ? {display: 'none'} : {}}>
                    {getFieldDecorator(`sample_size[${k}]`, {
                        initialValue: "Medium"
                    })(
                        <Select placeholder="Image size">
                            <Option key="Low">Low</Option>
                            <Option key="Medium">Medium</Option>
                            <Option key="High">High</Option>
                        </Select>
                    )}
                </Form.Item>
                <div className="image-wrapper">
                    <Form.Item className="image-upload" style={conf == 'Sample' ? {display: 'none'} : {}}>
                        {getFieldDecorator(`image[${k}]`, {
                            rules: [{whitespace: true}]
                    })(
                        <Upload {...{
                            name: 'files',
                            accept: 'image/gif, image/jpeg, image/svg+xml, image/png',
                            customRequest: (file) => this.customRequest(file, k),
                            onChange: (file) => {this.props.setFileList([file.file], k)},
                        }} fileList={this.props.file_list[k]} disabled={true} showUploadList={{showPreviewIcon: true, showRemoveIcon: false}}>
                            <Button disabled={this.props.config && conf != 'Sample' }>
                            <Icon type="upload" /> Click to Upload
                            </Button>
                        </Upload>
                    )}
                    </Form.Item>
                    {this.props.images[k] ? (<div className="image-tag" style={conf == 'Sample' ? {display: 'none'} : {}}>
                        <img src={`${config.api.base_url}/uploads/${this.props.match.params.report_id}/${this.props.match.params.version}/${this.props.images[k]}`} alt={'Preview loading...'} style={{height: '200px', width: 'auto', marginLeft: '20px'}} />
                    </div>) : 
                    (<div className="prev-image">
                        <b>Last uploaded image : </b>
                        <span>{this.props.images[this.getPrevKValue(k)] ? this.props.images[this.getPrevKValue(k)] : this.props.prev_image ? this.props.prev_image : ''}</span>
                    </div>)}
                </div>
                <div className="image-wrapper">
                    <Form.Item className="image-upload" style={conf != 'Sample' ? {display: 'none'} : {}}>
                        {getFieldDecorator(`sample_image[${k}]`, {
                            rules: [{validator: validator, whitespace: true}]
                    })(
                        <Upload {...{
                            name: 'files',
                            accept: 'image/gif, image/jpeg, image/svg+xml, image/png',
                            customRequest: (file) => this.customRequest(file, k),
                            onChange: (file) => {this.props.setFileList([file.file], k)},
                        }} fileList={this.props.file_list[k]} showUploadList={{showPreviewIcon: true, showRemoveIcon: false}}>
                            <Button disabled={this.props.config && conf != 'Sample' }>
                            <Icon type="upload" /> Click to Upload
                            </Button>
                        </Upload>
                    )}
                    </Form.Item>
                    {this.props.images[k] ? (<div className="image-tag" style={conf != 'Sample' ? {display: 'none'} : {}}>
                        <img src={`${config.api.base_url}/uploads/${this.props.match.params.report_id}/${this.props.match.params.version}/${this.props.images[k]}`} alt={'Preview loading...'} style={{height: '200px', width: 'auto', marginLeft: '20px'}} />
                    </div>) : 
                    (<div className="prev-image">
                        <b>Last uploaded image : </b>
                        <span>{this.props.images[this.getPrevKValue(k)] ? this.props.images[this.getPrevKValue(k)] : this.props.prev_image ? this.props.prev_image : ''}</span>
                    </div>)}
                </div>
            </div>
        )
    }

    getPrevKValue = (k) => {
        let prev_index = k - 1;
        let elements = this.props.form.getFieldValue('elements');
        while(elements && elements[prev_index] == null && prev_index > -1) {
            prev_index--;
        }
        return prev_index;
    }

    getTableItems = (index, k, display_none, conf) => {
        const { getFieldDecorator } = this.props.form;
        this.addTableRef(k);
        const data = this.props.tables[k] ? this.props.tables[k] : [[null, null],[null, null]];
        return (
            <div className="row-wrap table-container" style={display_none ? {display: 'none'} : {}}>
                <Form.Item label="Heading" className="table-item">
                    {getFieldDecorator(`heading[${k}]`)(
                        <Input placeholder="Heading" disabled={this.props.config && conf != 'Sample' }/>
                    )}
                </Form.Item>
                <Form.Item label="Source" className="table-item">
                    {getFieldDecorator(`source[${k}]`, {
                    rules: [{ required: true, message: 'This item is required!', whitespace: true }],
                    initialValue: "Mordor Intelligence"
                })(
                        <Input placeholder="Source" disabled={this.props.config && conf != 'Sample' }/>
                    )}
                </Form.Item>
                <Form.Item className="table-item">
                    {getFieldDecorator(`checkbox[${k}]`, {
                        valuePropName: 'checked',
                        initialValue: true
                    })(
                        <Checkbox disabled={this.props.config && conf != 'Sample' }>Table has header</Checkbox>,
                    )}
                </Form.Item>
                <div style={{minWidth: '250px', width: 'auto', minHeight: '120px', height:'auto', overflow: 'auto'}}>
                    <HotTable
                        ref={hotTableRefs[k]}
                        afterChange={() => {this.handleTableDataChange(k)}}
                        settings= {{
                            data: data,
                            contextMenu: true,
                            autoColumnSize: true,
                            autoRowSize: true,
                            minSpareRows: 0,
                            autoWrapCol: true,
                            cells: () => {
                                let cellProperties = {};
                                if(this.props.config && conf != 'Sample' )
                                    cellProperties.readOnly = true;
                                return cellProperties;
                            },
                            licenseKey: config.security.handsOnTable
                        }}
                        />
                </div>
            </div>
        )
    }

    getGraphItems = (index, k, display_none) => {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const data = this.props.graphs[k] && (Object.keys(this.props.graphs[k]).length) ? this.transformGraphLabels(this.props.graphs[k]) : [[null, null],[null, null]];
        
        this.addTableRef(k);
        return (
            <div className="graph-container" style={display_none ? {display: 'none'} : {}}>
                <div className="row-wrap">
                    <Form.Item label="Type" className="graph-type">
                        {getFieldDecorator(`type[${k}]`, {
                            initialValue: "supporting"
                        })(
                            <Select placeholder="Image size" onChange={(value) => this.graphTypeChange(value, k)}>
                                <Option key="existing">Existing</Option>
                                <Option key="supporting">Supporting</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Title" className="graph-type">
                        {getFieldDecorator(`title[${k}]`)(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item label="Source" className="graph-type">
                        {getFieldDecorator(`source[${k}]`, {
                            initialValue: "Mordor Intelligence"
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <GraphSearch k={k} form={this.props.form} setGraphIndex={this.props.setGraphIndex} 
                    setGraphValues={this.props.setGraphValues} data={this.props.data} setData={this.props.setData}/>
                    
                </div>
                {getFieldValue(`type[${k}]`) === 'supporting' ? 
                <div>
                <div className="row-wrap">
                    <Form.Item label="Heading" className="graph-item">
                        {getFieldDecorator(`heading[${k}]`, {
                        rules: [{ required: true, message: 'This item is required!', whitespace: true }],
                    })(
                            <Input placeholder="Heading" />
                        )}
                    </Form.Item>
                    <Form.Item label="Metric" className="graph-item">
                        {getFieldDecorator(`metric[${k}]`, {
                        rules: [{ required: true, message: 'This item is required!', whitespace: true }],
                    })(
                            <Input placeholder="Metric" />
                        )}
                    </Form.Item>
                    {getFieldDecorator(`graph_id[${k}]`, { initialValue: ''})}
                    <Form.Item label="Graph type" className="graph-item">
                        {getFieldDecorator(`graph_type[${k}]`, {
                        initialValue: 'bar',
                        rules: [{ required: true, message: 'This item is required!', whitespace: true }],
                    })(
                            <Select>
                                <Option key="bar">Bar</Option>
                                <Option key="line">Line</Option>
                                <Option key="pie">Pie</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Price" className="graph-item">
                        {getFieldDecorator(`price[${k}]`, {
                        rules: [{ required: true, message: 'This item is required!' }],
                    })(
                        <InputNumber placeholder="Price" />
                    )}
                    </Form.Item>
                </div>
                <div style={{minWidth: '250px', width: 'auto', minHeight: '120px', height:'auto', overflow: 'auto'}}>
                    <HotTable
                        ref={hotTableRefs[k]}
                        afterChange={() => {this.handleGraphDataChange(k)}}
                        settings= {{
                            data: data,
                            contextMenu: true,
                            autoColumnSize: true,
                            autoRowSize: true,
                            minSpareRows: 0,
                            autoWrapCol: true,
                            licenseKey: config.security.handsOnTable
                        }}
                        />
                    </div>
                </div>: ''}
            </div>
        )
    }

    handleTableDataChange = (k) => {
        if(hotTableRefs[k] && hotTableRefs[k].current)
            this.props.setTableIndex(k, hotTableRefs[k].current.hotInstance.getData());
    }

    handleGraphDataChange = (k) => {
        if(hotTableRefs[k] && hotTableRefs[k].current) {
            let graph = hotTableRefs[k].current.hotInstance.getData();
            this.props.setGraphIndex(k, this.transformGraph(graph));
        }
    }

    transformGraph = (graph) => {
        let new_graph = { xaxis: [], yaxis: [] };
        if(Array.isArray(graph) && graph.length) {
            new_graph.xaxis = graph[0]
            for(let i = 1; i < graph.length; i++) {
                if(Array.isArray(graph[i]) && graph[i].length) {
                    new_graph.yaxis.push({
                        label: graph[i][0],
                        data: graph[i].slice(1)
                    });
                }
            }
            return new_graph;
        } else {
            notification.error({
                message: 'Error!',
                description: 'Invalid data for graph'
            });
        }
    }

    transformGraphLabels = (labels) => {
        if(labels.xaxis && labels.yaxis) {
            let graph = [labels.xaxis];
            if(Array.isArray(labels.yaxis) && labels.yaxis.length) {
                for(let item of labels.yaxis) {
                    graph.push([item.label, ...item.data]);
                }
            }
            return graph;
        } else {
            return labels;
        }
    }

    validateClipboardTable = (table) => {
        if(!Array.isArray(table) || !Array.isArray(table[0])) {
            return false;
        }
        let cols = table[0].length;
        for(let row of table) {
            if(row.length !== cols) {
                notification.error({
                    message: 'Error!',
                    description: 'Uneven table'
                })
                return false;
            }
        }
        return true;
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });
        const keys = getFieldValue('keys');
        const element_options = section_elements.map(item => <Radio.Button key={item} value={item}>{item}</Radio.Button>)
        const config_options = config_elements.map(item => <Radio.Button key={item} value={item}>{item}</Radio.Button>)
        const graph_options = config_elements_graph.map(item => <Radio.Button key={item} value={item}>{item}</Radio.Button>)
        const midSectionButton = (k, index) => {
            if(index < keys.length - 1 && !this.props.config) {
                return (
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: 10, width: '90%', marginLeft: 9}}>
                        <Icon type="plus-circle" style={{fontSize: 22}} onClick={() => this.props.addMidItem(k, index)}/>
                    </div>
                )
            } else {
                return '';
            }
        }
        const formItems = keys.map((k, index) =>{
            if(!this.props.config) {
                if(!this.props.collapsed[index]) {
                    return  (
                        <Draggable draggableId={k.toString()} index={index}>
                            {provided => (
                                <div key={k} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <div className="element-wrapper" >
                                <div className="element-type" >
                                    <Form.Item label="Content-type" style={{width: 'auto'}}>
                                        {getFieldDecorator(`elements[${k}]`, {
                                            validateTrigger: ['onChange', 'onBlur'],
                                            initialValue: this.props.elements[index],
                                            rules: [{
                                                required: true,
                                                whitespace: true,
                                                message: "Please input element type!",
                                            }],
                                        })(
                                            <Radio.Group style={{width: '100%'}} onChange={(value) => this.elementChange(index, value, k)} buttonStyle={"solid"}>
                                                {element_options}
                                            </Radio.Group>
            
                                        )}
                                    </Form.Item>
                                    {getFieldDecorator(`ids[${k}]`,{initialValue : this.props.content && this.props.content[index] ? this.props.content[index]._id : null})}
                                    <div>
                                        <Icon className="dynamic-collapse-button" type="up"
                                            onClick={() => this.handleSectionCollapse(index)} />
                                        <Icon className="dynamic-delete-button" type="close" style={{marginLeft: 20}}
                                            onClick={() => this.props.remove_item(k, index)} />
                                    </div>
                                </div>
                                {this.getDynamicFormItems(index, k)}
                                </div>
                                {midSectionButton(k, index)}
                            </div>
                            )}
                        </Draggable>
                    );
                } else {
                    return (
                        <Draggable draggableId={k.toString()} index={index}>
                            {provided => (
                                <div key={k} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <div className="element-wrapper" style={{padding: 0}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <div style={{width: '12%', padding: '10px 20px', backgroundColor: '#1890ff', borderRadius: '10px 0px 0px 10px', color: 'white'}}>
                                            <span style={{display: 'flex', justifyContent: 'center'}}>{this.props.elements[index]}</span>
                                        </div>
                                        {this.getDynamicFormItemsCollapsed(index, k)}
                                        <Form.Item label="Content-type" style={{width: 'auto', display: 'none'}}>
                                            {getFieldDecorator(`elements[${k}]`, {
                                                validateTrigger: ['onChange', 'onBlur'],
                                                initialValue: this.props.elements[index],
                                                rules: [{
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please input element type!",
                                                }],
                                            })(
                                                <Radio.Group style={{width: '100%'}} onChange={(value) => this.elementChange(index, value, k)} buttonStyle={"solid"}>
                                                    {element_options}
                                                </Radio.Group>
                
                                            )}
                                        </Form.Item>
                                        {getFieldDecorator(`ids[${k}]`,{initialValue : this.props.content && this.props.content[index] ? this.props.content[index]._id : null})}                                                            
                                        <div style={{padding: '10px 20px'}}>
                                            {this.getDynamicFormItems(index, k, true)}
                                            <Icon className="dynamic-collapse-button" type="up"
                                                onClick={() => this.handleSectionCollapse(index)} 
                                                style={{fontSize: 12, transform: `rotate(${!this.props.collapsed[index]?'0deg':'180deg'})`, transition: '300ms ease all'}}
                                                />
                                            <Icon className="dynamic-delete-button" type="close" style={{marginLeft: 20}}
                                                onClick={() => this.props.remove_item(k, index)} />
                                        </div>
                                    </div>
                                </div>
                                {midSectionButton(k, index)}
                                </div>
                                )}
                        </Draggable>
                    ); 
                }  
            } else {
                if(!this.props.collapsed[index]) {
                    return  (
                            <div key={k}>
                            <div className="element-wrapper" >
                            <div className="element-type" >
                                <Form.Item label="Config-type" style={{width: 'auto'}}>
                                    {getFieldDecorator(`config[${k}]`, {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: this.props.content && this.props.content[index] && this.props.content[index].config ? this.props.content[index].config : 'Masked',
                                        rules: [{
                                            required: true,
                                            whitespace: true,
                                            message: "Please input config type!",
                                        }],
                                    })(
                                        <Radio.Group style={{width: '100%'}} buttonStyle={"solid"}>
                                            {config_list.indexOf(this.props.elements[index]) == -1 ? config_options : graph_options}
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <div>
                                    <Icon className="dynamic-collapse-button" type="up"
                                        onClick={() => this.handleSectionCollapse(index)} />
                                </div>
                            </div>
                            {this.getDynamicFormItems(index, k)}
                            </div>
                            {midSectionButton(k, index)}
                        </div>
                    );
                } else {
                    let config = this.props.form.getFieldValue('config');
                    let colors = {
                        Actual: '#1890ff',
                        Sample: '#87d068',
                        Masked: '#f50'
                    }
                    return (
                        <div key={k}>
                        <div className="element-wrapper" style={{padding: 0}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{width: '12%', padding: '12px 20px', backgroundColor: '#1890ff', borderRadius: '10px 0px 0px 10px', color: 'white'}}>
                                    <span style={{display: 'flex', justifyContent: 'center'}}>{this.props.elements[index]}</span>
                                </div>
                                {this.getDynamicFormItemsCollapsed(index, k)}
                                <Form.Item label="Config-type" style={{width: 'auto', display: 'none'}}>
                                    {getFieldDecorator(`config[${k}]`, {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: this.props.content && this.props.content[index] && this.props.content[index].config ? this.props.content[index].config: 'Masked',
                                        rules: [{
                                            required: true,
                                            whitespace: true,
                                            message: "Please input config type!",
                                        }],
                                    })(
                                        <Radio.Group style={{width: '100%'}} onChange={(value) => this.elementChange(index, value, k)} buttonStyle={"solid"}>
                                            {config_list.indexOf(this.props.elements[index]) == -1 ? config_options : graph_options}
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <div style={{padding: '10px 20px', position: 'relative'}}>
                                    {this.getDynamicFormItems(index, k, true)}
                                    <Icon className="dynamic-collapse-button" type="up"
                                        onClick={() => this.handleSectionCollapse(index)} 
                                        style={{fontSize: 12, transform: `rotate(${!this.props.collapsed[index]?'0deg':'180deg'})`, transition: '300ms ease all'}}
                                        />
                                    <Tag style={{position: 'absolute', top: this.props.elements[index] == 'Section-heading' ? -22 : -10}} color={config && config[index] ? colors[config[index]] : 'volcano'}>{config && config[index] ? config[index]: 'N/A'}</Tag>
                                </div>
                            </div>
                        </div>
                        {midSectionButton(k, index)}
                        </div>
                    ); 
                }
            }
        });

        return (
            <div ref={this.props.innerRef}>
                {formItems}
            </div>
        );
    }
};

export default withRouter(SectionFormItems);