import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Icon, Tooltip, InputNumber, notification, Modal } from 'antd';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import SectionFormItems from './SectionFormItems/SectionFormItems';
import { getPrevImage, getGraph } from './SectionContainerActions';

import { SectionWrapper } from './SectionContainer.style';

const confirm = Modal.confirm;

let id = 0;
let initial_elements = [];
let input_refs = [];
let data = [];
let columns = [];

class SectionContainer extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            fileList: {},
            initial: true,
            current_hash: null,
            prev_pages: null,
            prev_image: null,
            data: [],
            upload_in_progress: false
        }
        this.draggableRef = React.createRef();
    }

    componentDidMount () {
        window.addEventListener("beforeunload", (ev) => 
        { 
            let values = this.props.form.getFieldsValue();
            if(values && ((values.keys && values.keys.length) || values.pages)) {
                if(this.state.prev_pages) {
                    values['pages'] = this.state.prev_pages;
                }
                if(this.state.current_hash && (JSON.stringify(values, Object.keys(values).sort()) !== this.state.current_hash)) {
                    ev.preventDefault();
                    return ev.returnValue = 'Are you sure you want to close there is unsaved data left?';
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.section_id !== this.props.section_id && this.props.section_id) {
            getPrevImage(this.props.match.params.report_id, this.props.match.params.version, (url) => {
                let filename = url.file_name;
                filename = filename.split('_');
                if(filename && filename.length)
                    filename.splice(0, 1);
                filename = filename.join('_');
                this.setPrevImage(filename);
            })
            let data = this.props.form.getFieldsValue();
            if(data && ((data.keys && data.keys.length) || data.pages)) {
                if(this.state.prev_pages) {
                    data['pages'] = this.state.prev_pages;
                }
                if(!this.props.config && this.state.current_hash && (JSON.stringify(data, Object.keys(data).sort()) !== this.state.current_hash)) {
                    window.addEventListener("beforeunload", (ev) => 
                    { 
                        ev.preventDefault();
                        return ev.returnValue = 'Are you sure you want to close? There is unsaved data left.';    
                    });
                } else {
                    window.removeEventListener("beforeunload", (ev) => 
                    { 
                        ev.preventDefault();
                        return ev.returnValue = 'Are you sure you want to close? There is unsaved data left.';    
                    });
                }
            } else {
                window.removeEventListener("beforeunload", (ev) => 
                { 
                    ev.preventDefault();
                    return ev.returnValue = 'Are you sure you want to close? There is unsaved data left.';    
                })
            }
            this.initializeSection();
        } 
        if(this.props.submit_form) {
            this.handleSubmit(null, false, true);
            this.props.updateSubmitForm(false, this.props.tree_flag);
        }
    }

    reinitializeFields = () => {
        id = 0;
        initial_elements = [];
        input_refs = [];
        data = [];
        columns = [];
        this.props.reinitializeArrays();
        this.props.form.resetFields();
    }

    setData = (value) => {
        this.setState({
            data: value
        })
    }

    changeUploadProgress = (value) => {
        this.setState({
            ...this.state,
            upload_in_progress: value
        })
    }

    showSectionDeleteConfirm = (k, index) => {
        confirm({
            title: 'Are you sure delete this content?',
            content: 'This is an irreversible process',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk : () => {
                this.remove(k, index);
            },
            onCancel () {
            },
        });
    }

    setFileList = (file, k) => {
        let tempFileList = this.state.fileList; 
        tempFileList[k] = file;
        this.setState({
            ...this.state,
            fileList: tempFileList
        });
    }

    setPrevImage = (image) => {
        this.setState({
            ...this.state,
            prev_image: image
        })
    }

    emptyFileList = () => {
        this.setState({
            ...this.state,
            fileList: {}
        });
    }

    showSaveConfirm = (values, section_id, title) => {
        confirm({
            title: 'There are pending changes in the current section. Do you want to save them?',
            okText: 'Yes',
            cancelText: 'No',
            onOk : () => {
                for(let key in values) {
                    if(["pages", "keys"].indexOf(key) === -1) {
                        values[key] = this.remapElemToKeys(values[key]);
                    }
                }
                let keys = this.props.form.getFieldValue('keys');
                this.props.add_section(values, false, false, section_id, title, true, keys);
                this.props.setMenuSelect(false);
                this.initializeSection();
            },
            onCancel: () => {
                this.props.setMenuSelect(false);
                this.initializeSection();
            },
        });
    }
    
    initializeSection = () => {
        this.reinitializeFields();
        this.props.setGraph([]);
        this.props.getSection(this.props.section_id, this.props.user_config, this.populateSection);
    }

    handleDragAndDrop = (drag) => {
        if(drag.destination) {
            let keys = this.props.form.getFieldValue('keys');
            let collapsed = this.props.collapsed;
            let temp = collapsed.splice(drag.source.index, 1);
            collapsed.splice(drag.destination.index, 0, temp[0]);
            this.props.handleCollapsedSections(collapsed);
            temp = keys.splice(drag.source.index, 1);
            keys.splice(drag.destination.index, 0, temp[0]);
            temp = initial_elements.splice(drag.source.index, 1);
            initial_elements.splice(drag.destination.index, 0, temp[0]);
            this.props.form.setFieldsValue({
                'keys': keys,
            })
        }
    }

    populateSection = async (content, pages, config) => {
        this.reinitializeFields();
        if(pages)
            this.prePopulatePages(pages);
        for(let item of content) {
            if(item.data && item.data.type) {
                switch(item.data.type) {
                    case 'Heading':
                    case 'Heading2':
                    case 'Heading3':
                    case 'Paragraph':
                    case 'List':
                    case 'Section-heading':
                        this.add(null, item.data.type, item.data.value, item._id);
                        break;
                    case 'Image':
                        this.prepopulateImage(item.data.value, item._id);
                        break;
                    case 'Table':
                        this.prepopulateTable(item.data.value, item._id);
                        break;
                    case 'Graph':
                        await this.prepopulateGraph(item.data.value, item._id);
                        break;
                    default:
                        break;
                }
            }
            if(this.props.config && item.sample && item.sample.type) {
                switch(item.sample.type) {
                    case 'Heading':
                    case 'Heading2':
                    case 'Heading3':
                    case 'Paragraph':
                    case 'List':
                    case 'Section-heading':
                        this.setSampleGeneralItemValue(item.sample.value ? item.sample.value: item.data.value);
                        break;
                    default:
                        break;
                }
            } else {
                switch(item.data.type) {
                    case 'Heading':
                    case 'Heading2':
                    case 'Heading3':
                    case 'Paragraph':
                    case 'List':
                    case 'Section-heading':
                        this.setSampleGeneralItemValue(item.data.value);
                        break;
                    default:
                        break;
                }
            }
        }
        if(config)
            this.prePopulateConfig(config, content);
        let values = this.props.form.getFieldsValue();
        if(values && (values.keys || values.pages)) {
            this.setState({
                ...this.state,
                current_hash: JSON.stringify(values, Object.keys(values).sort()),
                prev_pages: values.pages ? values.pages : null
            }, () => {
                if(values && values.keys) {
                    let collapsed = [];
                    values.keys.map((elem) => collapsed[elem] = true);
                    this.props.handleCollapsedSections(collapsed);
                }
            })
        }
    }

    remove = (k, index) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        initial_elements.splice(index, 1);
        input_refs.splice(index, 1);
        this.props.removeImage(k);
        this.setFileList([], k);
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
        let collapsed = this.props.collapsed;
        collapsed.splice(index, 1);
        this.props.handleCollapsedSections(collapsed);
    }

    add = (event, type, value, _id=null) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const ids = form.getFieldValue('ids');
        if(keys.length < 75) {
            const nextKeys = keys.concat(id++);
            let temp_ids = ids && ids.length ? ids.concat(_id) : [_id];
            let index = nextKeys.length - 1;
            if(index > -1)
                initial_elements[index] = value ? type : this.getAddType(type, index);
            form.setFieldsValue({
                keys: nextKeys
            }, () => {
                this.setLatestGeneralItemValue(type, value, index);
                this.scrollRef.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            });
        } else {
            notification.error({
                message: 'Error!',
                description: 'Too much content in a single section. Please limit it to 75 containers a section.'
            })
        }
        
    }

    setSampleGeneralItemValue = (value) => {
        this.props.form.setFieldsValue({
            [`samples[${id - 1}]`]: value
        })
    }

    addMidItem = (k, index) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        keys.splice(index+1, 0, id++);
        initial_elements.splice(index+1, 0, 'List');
        input_refs.splice(index+1, 0, null);
        let collapsed = this.props.collapsed;
        collapsed.splice(index+1, 0, false);
        form.setFieldsValue({
            keys
        }, () => {
            if(input_refs[index + 1] && input_refs[index + 1].current && input_refs[index + 1].current.textAreaRef)
                input_refs[index + 1].current.textAreaRef.scrollIntoView({ behavior: "smooth", inline: "nearest", block: "end"})
        });
    }

    setLatestGeneralItemValue = (type, value, index) => {
        if(value) {
            this.props.form.setFieldsValue({
                [`values[${id - 1}]`]: value
            })
        }
        if(input_refs[index - 1] && input_refs[index - 1].current && input_refs[index - 1].current.textAreaRef && !value) {
            this.copyOldValue(this.getAddType(type, index), input_refs[index - 1].current.textAreaRef.selectionStart, index);
        }
    }

    getAddType = (type, index) => {
        let allowed_types = ['Paragraph', 'List', 'Heading', 'Heading2', 'Heading3'];
        if(type) {
            return type;
        } else {
            let elements = this.props.form.getFieldValue('elements');
            let prev_index = id - 2 === -1 ? 0 : id - 2;
            let values = this.props.form.getFieldValue('values');
            if(values && values.length) {
                while(elements[prev_index] == null && prev_index > -1) {
                    prev_index--;
                }
            }
            if(Array.isArray(elements) && elements[prev_index] && allowed_types.includes(elements[prev_index])) {
                return elements[prev_index];
            } else {
                return 'List';
            }
        }
    }

    copyOldValue = (type, selection_start, index = null) => {
        let allowed_types = ['Paragraph', 'List', 'Heading', 'Heading2', 'Heading3'];
        if(allowed_types.indexOf(type) !== -1) {
            let values = this.props.form.getFieldValue('values');
            let prev_index = id - 2;
            if(index && this.props.collapsed[index - 1]) {
                return;
            }
            while(values[prev_index] == null && prev_index > -1) {
                prev_index--;
            }
            if(values[prev_index] && prev_index > -1) {
                this.props.form.setFieldsValue({
                    [`values[${id - 1}]`]: values[prev_index].slice(selection_start),
                    [`values[${prev_index}]`]: values[prev_index].substring(0, selection_start)
                })
            }
        }
    }

    prePopulatePages = (pages) => {
        this.props.form.setFieldsValue({
            pages: pages
        });
    }

    prePopulateConfig = (config, content) => {
        content.map((elem, index) => {
            this.props.form.setFieldsValue({
                [`config[${index}]`]: config
            })
        });
    }

    prepopulateImage = (item, _id) => {
        this.add(null, 'Image', undefined, _id);
        this.props.form.setFieldsValue({
            [`heading[${id - 1}]`]: item.meta.heading,
            [`source[${id - 1}]`]: item.meta.source,
            [`size[${id - 1}]`]: item.meta.size
        });
        this.props.setImageIndex(id - 1, item.url);
    }

    setSampleImageValue = (item) => {
        this.props.form.setFieldsValue({
            [`sample_heading[${id - 1}]`]: item.meta.heading,
            [`sample_source[${id - 1}]`]: item.meta.source,
            [`sample_size[${id - 1}]`]: item.meta.size
        });
    }

    prepopulateGraph = async (item, _id) => {
        if(!item)
            return;
        let graph_to_get = typeof(item) === 'string' ? item : item.graph_ids;
        if(!graph_to_get)
            return;
        let response = await getGraph(graph_to_get);
        this.add(null, 'Graph', undefined, _id);
        let keys = this.props.form.getFieldValue('keys');
        if(response.data[0] && response.data[0].supported) {
            this.props.form.setFieldsValue({
                [`type[${id - 1}]`] : 'supporting',
                [`heading[${id - 1}]`]: response.data[0].heading,
                [`price[${id - 1}]`]: response.data[0].price,
                [`graph_type[${id - 1}]`]: response.data[0].type,
                [`graph_id[${id - 1}]`]: response.data[0]._id,
                [`metric[${id - 1}]`]: response.data[0].labels.metric,
                [`title[${id - 1}]`]: item.meta ? item.meta.title : '',
                [`source[${id - 1}]`]: item.meta ? item.meta.source : ''
            });
            this.props.setGraphIndex(id - 1, this.transformGraphLabels(response.data[0].labels));
            this.props.setGraphValues(keys[id - 1], item);
        } else {
            this.props.form.setFieldsValue({
                [`type[${id - 1}]`] : 'existing',
                [`search[${id - 1}]`]: item.graph_ids,
                [`title[${id - 1}]`]: item.meta ? item.meta.title : '',
                [`source[${id - 1}]`]: item.meta ? item.meta.source : ''
            });
            this.props.setGraphValues(keys[id - 1], item);
        }
    }

    transformGraphLabels = (labels) => {
        let graph = [labels.xaxis];
        if(Array.isArray(labels.yaxis) && labels.yaxis.length) {
            for(let item of labels.yaxis) {
                graph.push([item.label, ...item.data]);
            }
        }
        return graph;
    }

    remapElemToKeys = (elems) => {
        let keys = this.props.form.getFieldValue('keys');
        return elems ? keys.map((key) => { return elems[key]}) : keys;
    }

    prepopulateTable = (item, _id) => {
        this.props.setTableIndex(id, item.table);
        this.createTable(item.table, id);
        this.add(null, 'Table', undefined, _id);
        this.props.form.setFieldsValue({
            [`heading[${id - 1}]`]: item.meta.heading,
            [`source[${id - 1}]`]: item.meta.source,
            [`checkbox[${id - 1}]`]: item.meta.table_has_header,
            [`values[${id - 1}]`]: ''
        });
    }

    createTable = (clipText, k) => {
        columns[k] = [];
        data[k] = [];
        this.createHeaders(clipText, k);
        this.createRows(clipText, k);
    }

    createHeaders = (table, k) => {
        for(let item of table[0]) {
            columns[k].push({
                title: item,
                key: item,
                dataIndex: item
            });
        }
    }

    createRows = (table, k) => {
        for(let i = 1; i < table.length; i++) {
            let row = {};
            for(let item in table[i]) {
                row.key = item;
                row[columns[k][item].dataIndex] = table[i][item];
            }
            data[k].push(row);
        }
    }

    handleSubmit = (e, move_flag, preview_flag) => {
        if(e)
            e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                for(let key in values) {
                    if(["pages", "keys"].indexOf(key) === -1) {
                        values[key] = this.remapElemToKeys(values[key]);
                    }
                }
                let keys = this.props.form.getFieldValue('keys');
                this.props.add_section(values, move_flag, preview_flag, undefined, undefined, true, keys);
                if(values && (values.keys || values.pages)) {
                    this.setState({
                        ...this.state,
                        current_hash: JSON.stringify(values, Object.keys(values).sort()),
                        prev_pages: values.pages ? values.pages : null
                    })
                }
            } else {
                console.log(err);
                notification.error({
                    message: 'Error',
                    description: 'Error occurred. Contact an administrator.!'
                });
            }
        });
    }

    render() {
        const top_section_boolean = !isNaN(this.props.toc_location) || (this.props.toc_location.split('_').length === 2 && this.props.toc_location.split('_')[1] === '0');
        const pages_component =  top_section_boolean ? <Form.Item>
            {this.props.form.getFieldDecorator(`pages`, {
                rules: [{required: true, message: 'Number of pages is required.'}]
            })(<InputNumber placeholder="Pages" key={'pages'} min={1} onChange={(value) => this.setState({...this.state, prev_pages: value })} disabled={this.props.config}/>
            )}</Form.Item>: '';
        const side_bar = !this.props.config ? 
            <div className="section-actions">
                <Tooltip title="Add base item" placement="left">
                    <Icon type="plus-circle" className="section-action-icon"
                    onClick={(e) => this.add(e)}/>
                </Tooltip>
                <Tooltip title="Add table" placement="left">
                    <Icon type="table" className="section-action-icon" 
                    onClick={(e) => this.add(e, 'Table')}/>
                </Tooltip>
                <Tooltip title="Add image" placement="left">
                    <Icon type="picture" className="section-action-icon" 
                    onClick={(e) => this.add(e, 'Image')}/>
                </Tooltip>
                <Tooltip title="Save" placement="left">
                    <Icon type="save" className="section-action-icon" 
                    onClick={(e) => {this.handleSubmit(e, false)}}/>
                </Tooltip>
                <Tooltip title="Next" placement="left" htmlType="submit">
                    <Icon type="arrow-right" className="section-action-icon" 
                    onClick={(e) => {this.handleSubmit(e, true)}}/>
                </Tooltip>
            </div> 
            :
            <div className="section-actions"> 
                <Tooltip title="Save" placement="left">
                    <Icon type="save" className="section-action-icon" 
                    onClick={(e) => {this.handleSubmit(e, false)}}/>
                </Tooltip>
                <Tooltip title="Next" placement="left" htmlType="submit">
                    <Icon type="arrow-right" className="section-action-icon" 
                    onClick={(e) => {this.handleSubmit(e, true)}}/>
                </Tooltip>
            </div>;

        return (
                <SectionWrapper>
                    <Form onSubmit={this.handleSubmit}>
                    {pages_component}
                    <DragDropContext onDragEnd={(drag) => {this.handleDragAndDrop(drag)}}>
                        <Droppable droppableId={1}>
                            {provided => (
                                <SectionFormItems {...provided.droppableProps} handleCollapsedSections={this.props.handleCollapsedSections}
                                innerRef={provided.innerRef} form={this.props.form} add_item={this.add} setPrevImage={this.setPrevImage}
                                setFileList={this.setFileList} file_list={this.state.fileList} emptyFileList={this.emptyFileList}
                                remove_item={this.showSectionDeleteConfirm} elements={initial_elements} prev_image={this.state.prev_image} collapsed={this.props.collapsed}
                                removeImage={this.props.removeImage} section_id={this.props.section_id} addMidItem={this.addMidItem} setData={this.setData}
                                tables={this.props.tables} images={this.props.images} graphs={this.props.graphs} changeUploadProgress={this.changeUploadProgress}
                                setTableIndex={this.props.setTableIndex} setImageIndex={this.props.setImageIndex} setGraphIndex={this.props.setGraphIndex}
                                input_refs={input_refs} create_table={this.createTable} content={this.props.content} setGraphValues={this.props.setGraphValues}
                                columns={columns} config={this.props.config} user_config={this.props.user_config} data={this.state.data}>
                                </SectionFormItems>
                            )}
                        </Droppable>
                    </DragDropContext>
                    </Form>
                    {side_bar}
                    <div ref={(el) => this.scrollRef = el}></div>
                </SectionWrapper>
        );
    }
}

export default withRouter(SectionContainer);