import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import {Table, Button, Modal} from 'antd';
import {getReports, getUserDetails, getUserCustomizations, getUserCallbacks} from './UserConfigActions';
import {UserConfigComponentWrapper} from './UserConfigStyle';
import UserQueryComponent from './UserQueryComponent';

class UserConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            data: [],
            visible: false,
            title: ''
        };

        this.columns = [
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: 'Config',
              key: 'config',
              render: (elem) => <Link to={{pathname: "/config/" + elem.report_id + "/" + elem.version, search: 'user=' + this.props.match.params.id}}><Button type="primary">Config</Button></Link>
            },
            {
              title: 'Customization',
              key: 'customize',
              render: (elem) => <Button onClick={() => {this.handleGetUserCustomizations(elem._id, elem.title)}} type="primary">View customizations</Button>
            },
            {
                title: 'Callback',
                key: 'customize',
                render: (elem) => <Button onClick={() => {this.handleGetUserCallbacks(elem._id, elem.title)}} type="primary">View callbacks</Button>
            },
        ];
    }

    handleGetUserCallbacks = (id, title) => {
        this.setState({
            title: title + ' : Callbacks'
        })
        getUserCallbacks(id, this.setUserQueries);
    }
    
    handleGetUserCustomizations = (id, title) => {
        this.setState({
            title: title + ' : Customizations'
        })
        getUserCustomizations(id, this.setUserQueries);
    }

    setUserDetails = (user) => {
        this.setState({
            user: user
        })
    }

    setUserQueries = (data) => {
        this.showModal();
        this.setState({
            data: data
        })
    }

    showModal = () => {
        this.setState({
          visible: true,
        });
    };
    
    handleOk = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 3000);
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    componentDidMount = () => {
        getUserDetails(this.props.match.params.id, this.setUserDetails);
        this.props.getReports(this.props.match.params.id);
    }

    render() {

        const user_details = 
        <div className='user-details'>
            <div>
                <span className='label'>
                    Email : 
                </span>
                <span>
                    {this.state.user.email}
                </span>
            </div>
            <div>
                <span className='label'>
                    Remaining Credit : 
                </span>
                <span>
                    {this.state.user.credit}
                </span>
            </div>
        </div>;

        const report_list = 
        <Table bordered
        columns={this.columns}
        dataSource={this.props.reports.list}
        />;
 
        return <UserConfigComponentWrapper>
            {user_details}
            {report_list}
            <Modal
                visible={this.state.visible}
                onOk={this.handleOk}
                width={1200}
                title={this.state.title}
                onCancel={this.handleCancel}
                footer={null}
            >
                <UserQueryComponent data={this.state.data}/>
            </Modal>
        </UserConfigComponentWrapper>
    }
}

function mapStateToProps(state) {
    return {
        reports: state.USERREDUCER.REPORTS
    }
}

const mapDispatchToProps = {
    getReports
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserConfig));