import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginRequest } from './LoginActions';
import { notification } from 'antd';
import AuthComponent from './LoginComponent';

const handleFailure = () => {
    notification.error({
        duration: 2,
        message: 'Error',
        description: 'There was an error during Login. Please contact an administrator.'
    })
}

class LoginContainer extends Component {

    handleSuccess = (token) => {
        // this.props.loginRequest(token.tokenId);
        this.props.loginRequest();
    }

    render() {
        return (<AuthComponent handleSuccess={this.handleSuccess} handleFailure={handleFailure}/>);
    }
    
}

function mapStateToProps(state) {
    return {
    }
}
  
const mapDispatchToProps = {
    loginRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);