import axios from 'axios';
import config from '../../config/config';

import { notification } from 'antd';

export const setTOCDetails = (data) => ({
    type: 'SET_TOC_DETAILS',
    data
});

export const setReportDetails = (data) => ({
    type: 'SET_REPORT_DETAILS',
    data
});

export const setSectionDetails = (data) => ({
    type: 'SET_SECTION_DETAILS',
    data
});

export const setSectionMode = (data) => ({
    type: 'SET_SECTION_MODE',
    data
});

export const getReportDetails = (token, id, version) => {
    return function(dispatch) {
        axios.get(`${config.api.base_url}${config.api.report_details}?id=${id}&version=${version}`,
        {headers: {authorization: token}})
        .then(response => {
            dispatch(setTOCDetails(response.data.toc));
            let temp_data = {
                id: id,
                version: version,
                title: response.data.title
            };
            dispatch(setReportDetails(temp_data));
        }).catch(error => {
            console.error(error);
            notification.error({
                message: 'Error!',
                description: 'Unable to fetch report details.'
            })
        });
    }
}

export const deleteSection = (token, id, version, section_id, callback) => {
    axios.get(config.api.base_url + config.api.delete_section + '?id='
    + id + '&version=' + version + '&section_id=' + section_id, {headers: {authorization: token}})
    .then(response => {
        notification.success({
            message: 'Success',
            description: response.data && response.data.message ? response.data.message : 'Successfully deleted the section and all its subsections!'
        })
        callback();
    }).catch(error => {
        notification.error({
            message: 'Error!',
            description: 'Something went wrong. Please contact your admin!'
        });
    });
}