const initialState = {
  ACTIVE_REPORT: {
    id: null,
    version: null,
    title: null
  },
  ACTIVE_SECTION: {
    section_id: null,
    title: null,
    content: [],
    sample_mode: false
  },
  ACTIVE_TOC: {
    tocTree: null,
    location: null,
    move_flag: false,
    menu_select: false
  }
};

export const REPORTREDUCER = (state = initialState, action) => {

  switch(action.type) {

    case 'SET_REPORT_DETAILS': 
      return {
        ...state,
        ACTIVE_REPORT: action.data
      }

    case 'SET_SECTION_DETAILS':
      return {
        ...state,
        ACTIVE_SECTION: action.data
      };

    case 'SET_SECTION_MODE':
      return {
        ...state,
        ACTIVE_SECTION: {
          ...state.ACTIVE_SECTION,
          mode: action.data
        }
      };

    case 'SET_TOC_DETAILS':
      return {
        ...state,
        ACTIVE_TOC: action.data
      };

    case 'RESET_REPORT_REUCER':
      return initialState;

    default:
      return state;
      
  }
};