import axios from 'axios';
import config from '../../config/config';

import { notification } from 'antd';

export const setUsers = (data) => ({
    type: 'SET_USERS',
    data
});

export const getUsers = () => {
    return function(dispatch) {
        dispatch(setUsers({loading: true, list: []}));
        axios.get(`${config.api.base_url + config.api.get_platform_users}`, {headers: {authorization: localStorage.getItem('token')}})
        .then(response => {
            dispatch(setUsers({loading: false, list: response.data}));
        }).catch(error => {
            dispatch(setUsers({loading: false, list: []}));
            notification.error({
                message: 'Error!',
                description: 'Unable to fetch cms users!'
            })
        });
    }
}

export const toggleStatus = (id, callback) => {
    axios.patch(`${config.api.base_url + config.api.toggle_status + `/${id}`}`, {},{headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        notification.success({
            message: 'Success!',
            description: 'Toggled status of user!'
        });
        callback();
    }).catch(error => {
        console.log(error);
        notification.error({
            message: 'Error!',
            description: 'Unable to toggle status of user!'
        })
    });
}

export const updateWallet = (id, credits, callback) => {
    axios.patch(`${config.api.base_url + config.api.update_wallet + `/${id}`}`, {credits: credits}, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        notification.success({
            message: 'Success!',
            description: 'Updated wallet of user!'
        })
        callback();
    }).catch(error => {
        console.log(error);
        notification.error({
            message: 'Error!',
            description: 'Unable to update wallet of user!'
        })
    });
}