import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ContentHeader from './ContentHeader/ContentHeader';
import SectionContainer from './Section/SectionContainer';
import { addSection, previewSection, addDefaultConfig, addUserConfig, addSampleData,
addSupportingGraph } from './ContentContainerActions';
import { ContentWrapper } from './ContentContainer.style';
import { notification, Form } from 'antd';

class ContentContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submit_form: false,
            tables: [],
            images: [],
            graphs: [],
            titles: [],
            graph_values: {},
            tree_flag: false,
            collapsed: []
        }
        this.user_config = this.props.location.search ? new URLSearchParams(this.props.location.search).get('user') : null;
    }

    reinitializeArrays = () => {
        this.setState({
            tables: [],
            images: []
        });
    }

    handleCollapsedSections = (value) => {
        this.setState({
            collapsed: value
        })
    }

    handleCollapse = (value) => {
        let collapsed = [];
        let keys = this.props.form.getFieldValue('keys')
        if(value)
            collapsed = keys.map((elem) => true)
        else
            collapsed = keys.map((elem) => false)
        this.setState({
            collapsed: collapsed
        })
    }

    handleConfigChange = (value) => {
        let config = this.props.form.getFieldValue('config');
        let elements = this.props.content;
        if(config && config.length) {
            for(let i = 0; i < config.length; i++) {
                if(config[i]) {
                    if((elements[i].data.type == 'Image' || elements[i].data.type == 'Table' || elements[i].data.type == 'Graph' )&& value == 'Sample')
                        config[i] = 'Masked';
                    else
                        config[i] = value;
                }
            }
        }
        this.props.form.setFieldsValue({
            config: config
        })
    }

    updateSubmitForm = (value, parent) => {
        this.setState({...this.state, submit_form: value, tree_flag: parent});
    }

    setImageIndex = (index, value) => {
        let temp_images = this.state.images;
        temp_images[index] = value;
        this.setState({
            ...this.state,
            images: temp_images
        })
    }

    removeImage = (index) => {
        let temp_images = this.state.images;
        delete temp_images[index];
        this.setState({
            ...this.state,
            images: temp_images
        });
    }

    setTableIndex = (index, value) => {
        let temp_tables = this.state.tables;
        temp_tables[index] = value;
        this.setState({
            ...this.state,
            tables: temp_tables
        });
    }

    setGraph = (graph) => {
        this.setState({
            graphs: graph
        })
    }

    setGraphIndex = (index, value, cb=() => {}) => {
        let temp_graphs = this.state.graphs;
        temp_graphs[index] = value;
        this.setState({
            graphs: temp_graphs
        }, () => {
            cb();
        });
    }

    setGraphValues = (index, graphs) => {
        let temp_graph_values = this.state.graph_values;
        temp_graph_values[index] = graphs;
        this.setState({
            graph_values: temp_graph_values
        })
    }

    handleAddSection = async (values, move_flag=true, preview_flag=false, section_id=this.props.section_id, title=this.props.title, save_flag=false, keys) => {
        let token = localStorage.getItem('token');
        try {
            let formatted_values = await this.formatFormValues(values, save_flag, keys);
            let id = this.props.match.params.report_id;
            let version = this.props.match.params.version;
            if(this.props.config) {
                let section_config = {}, section_flag = true;
                section_config[section_id] = {};
                for(let index in values.config) {
                    if(index > 0 && values.config[index] != values.config[index - 1])
                        section_flag = false;
                }
                for(let index in values.config) {
                    if(this.props.content && this.props.content[index]) {
                        if(section_flag) {
                            section_config[section_id] = values.config[index];
                            break;
                        }
                        else
                            section_config[section_id][this.props.content[index]._id] = values.config[index];
                    }
                }
                let body = {
                    report_id: id,
                    version: version,
                    section_config
                };
                
                if(this.user_config) 
                    body['user_id'] = this.user_config;
    
                !this.user_config ? 
                addDefaultConfig(token, body, () => {
                    let sample_body = {
                        content : []
                    };
                    let samples = this.props.form.getFieldValue('samples');
                    for(let index in values.config) {
                        let temp_body = {};
                        temp_body['_id'] = this.props.content && this.props.content[index] ? this.props.content[index]._id : null;
                        temp_body['type'] = this.props.content && this.props.content[index] ? this.props.content[index].data.type : null;
                        temp_body['value'] = samples && samples[index] ? samples[index] : null;
                        sample_body.content.push(temp_body);
                    }
                    addSampleData(token, sample_body, section_id, () => {
                        if(move_flag)
                            this.props.setMoveFlag(true);
                    });
                })
                :
                addUserConfig(token, body, () => {
                    if(move_flag)
                        this.props.setMoveFlag(true);
                });
            } else {
                let body = {
                    title: title,
                    content: formatted_values
                }
                let content = [];
                for(let index in body.content) {
                    if(body.content[index]) {
                        let temp = {
                            data: body.content[index]
                        }
                        if(values.ids[keys[index]])
                            temp['_id'] = values.ids[keys[index]];
                        content.push(temp);
                    }
                }
                body['pages'] = values.pages || null;
                body.content = content;
                let preview = preview_flag ? this.handlePreviewSection : null;
                addSection(token, body, id, section_id, () => {
                    if(move_flag)
                        this.props.setMoveFlag(true);
                }, preview);

            }
        } catch (err) {
            notification.error({
                message: 'Error',
                description: 'Failed to add graph. Please check your graph.'
            });
        }
    }

    formatFormValues = async (values, save_flag=false, keys) => {
        let content = [], images = [], tables = [], graphs = [];
        if(save_flag) {
            images = keys.map((key) => { return this.state.images[key]});
            tables = keys.map((key) => { return this.state.tables[key]});
            graphs = keys.map((key) => { return this.state.graphs[key]});
        }
        for(let item in values.elements) {
            switch(values.elements[item]) {
                case 'Heading':
                case 'Heading2':
                case 'Heading3':
                case 'Paragraph':
                case 'List':
                case 'Section-heading':
                    content.push({type: values.elements[item], value: values.values[item]});
                    break;
                case 'Image':
                    this.pushImage(content, values, item, save_flag, images);
                    break;
                case 'Table':
                    this.pushTable(content, values, item, save_flag, tables);
                    break;
                case 'Graph':
                    try {
                        await this.pushGraph(content, values, item, save_flag, graphs);
                    } catch (err) {
                        throw err;
                    }
                    break;
                default:
                    break;
            }
        }

        return content;
    }

    handlePreviewSection = (section_id, report_id, title) => {
        previewSection(section_id, report_id, this.props.match.params.version, this.state.tree_flag, (response) => {
            if(response.htmlString) {
                let new_window = window.open('');
                new_window.document.body.innerHTML = response.htmlString;
                new_window.document.title = title;
            } else {
                notification.error({
                    message: 'Error',
                    description: 'No valid content to preview'
                });
            }
        })
    }

    pushImage = (content, values, index, save_flag=false, images=[]) => {
        let temp_image = null;
        if(save_flag)
            temp_image = images[index];
        else
            temp_image = this.state.images[index];
        let image_to_push = {
            url: temp_image,
            meta: {
                heading: values.heading[index],
                source: values.source[index],
                size: values.size[index]
            }
        }
        content.push({type: values.elements[index], value: image_to_push});
    }

    pushTable = (content, values, index, save_flag=false, tables=[]) => {
        let temp_table = null;
        if(save_flag)
            temp_table = tables[index];
        else
            temp_table = this.state.tables[index];
        let table_to_push = {
            table: temp_table,
            meta: {
                heading: values.heading[index],
                table_has_header: values.checkbox[index] ? true: false,
                source: values.source[index],
                rows: temp_table.length,
                columns: temp_table[0].length
            }
        };
        content.push({type: values.elements[index], value: table_to_push});
    }

    pushGraph = async (content, values, index, save_flag, graphs) => {
        let temp_graph = null;
        if(save_flag)
            temp_graph = graphs[index];
        else
            temp_graph = this.state.graphs[index];
        if(Array.isArray(temp_graph))
            temp_graph = this.transformGraph(temp_graph);
        if(temp_graph && values.type[index] === 'supporting') {
            temp_graph.metric = values.metric[index];
            try {
                let response = await addSupportingGraph(this.props.match.params.report_id,
                    temp_graph, values.heading[index], values.graph_type[index],
                    values.price[index], values.graph_id[index]);
                this.props.form.setFieldsValue({
                    [`graph_id[${index}]`]: response.data._id
                });
                this.setGraphValues(index, [response.data._id]);
                content.push({
                    type: values.elements[index],
                    value: {
                        graph_ids: [response.data._id],
                        meta: {
                            title: values.title[index],
                            source: values.source[index]
                        }
                    }});
            } catch(err) {
                console.log(err);
                throw err;
            }
        }
        else {
            content.push({
                type: values.elements[index], 
                value: {
                    graph_ids: values.search[index],
                    meta: {
                        title: values.title[index],
                        source: values.source[index]
                    }
                }
            });
        }

    }

    transformGraph = (graph) => {
        let new_graph = { xaxis: [], yaxis: [] };
        if(Array.isArray(graph) && graph.length) {
            new_graph.xaxis = graph[0]
            for(let i = 1; i < graph.length; i++) {
                if(Array.isArray(graph[i]) && graph[i].length) {
                    new_graph.yaxis.push({
                        label: graph[i][0],
                        data: graph[i].slice(1)
                    });
                }
            }
            return new_graph;
        }
    }

    render() {
        return (
            <ContentWrapper>
                <ContentHeader title={this.props.title} handleCollapse={this.handleCollapse} handleConfigChange={this.handleConfigChange} config={this.props.config}/>
                <SectionContainer section_id={this.props.section_id} handleCollapsedSections={this.handleCollapsedSections}
                add_section={this.handleAddSection} submit_form={this.state.submit_form} setMenuSelect={this.props.setMenuSelect} 
                menu_select={this.props.menu_select} form={this.props.form} updateSubmitForm={this.updateSubmitForm} collapsed={this.state.collapsed}
                move_flag={this.props.move_flag} title={this.props.title} tree_flag={this.state.tree_flag} getSection={this.props.getSection}
                tables={this.state.tables} images={this.state.images} graphs={this.state.graphs} setImageIndex={this.setImageIndex} removeImage={this.removeImage} user_config={this.user_config}
                setTableIndex={this.setTableIndex} setGraph={this.setGraph} setGraphIndex={this.setGraphIndex} toc_location={this.props.toc_location} changeMode={this.props.changeMode}
                reinitializeArrays={this.reinitializeArrays} content={this.props.content} config={this.props.config} setGraphValues={this.setGraphValues}/>
            </ContentWrapper>
        );
    }
}

export default Form.create()(withRouter(ContentContainer));