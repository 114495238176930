import React from 'react';
import { Button, Icon, Dropdown, Menu } from 'antd';
import { ContentHeaderWrapper } from './ContentHeader.style';

const ContentHeader = ({title, handleCollapse, handleConfigChange, config}) => {

    const fold_menu = 
    <Menu>
        <Menu.Item onClick={() => handleCollapse(true)}>
            <Icon type="menu-fold" /> Fold All
        </Menu.Item>
        <Menu.Item onClick={() => handleCollapse(false)}> 
            <Icon type="menu-unfold" /> Unfold All 
        </Menu.Item>
    </Menu>;

    const mask_menu =
    <Menu>
        <Menu.Item onClick={() => handleConfigChange('Masked')}>
            <Icon type="eye-invisible" /> Mask All
        </Menu.Item>
        <Menu.Item onClick={() => handleConfigChange('Sample')}> 
            <Icon type="eye" /> Sample for all 
        </Menu.Item>
        <Menu.Item onClick={() => handleConfigChange('Actual')}> 
            <Icon type="eye" theme="filled"/> Actual for all 
        </Menu.Item>
    </Menu>;

    const fold = 
    <Dropdown overlay={fold_menu} trigger={['click']}>
        <Button><Icon type="bars" /> Fold/Unfold All </Button>
    </Dropdown>;

    const mask = config ?
    <Dropdown overlay={mask_menu} trigger={['click']}>
        <Button><Icon type="eye" /> Mask/Unmask All </Button>
    </Dropdown> : '';

    return (
        <ContentHeaderWrapper>
            <h2>{title}</h2>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                {mask}
                {fold}
            </div>
        </ContentHeaderWrapper>
    );
};

export default ContentHeader;