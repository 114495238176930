import api from './api';
import options from './options';

const config = {
    api: api,
    security: {
        handsOnTable: '3d74f-b4719-4cb64-24438-8ab1f'
    },
    geography: options.geography,
    country: options.country,
    region: options.region,
    global: options.global,
    google_client_id: '545924306960-jug7enaj3kcf1nsdssnm61glbu62lr0k.apps.googleusercontent.com',
}

// Deciding base_url based on environment variables.
config.api.base_url = window.location.href.indexOf('mordorintelligence') !== -1 ? config.api.base_url_prod : window.location.href.indexOf('netlify') !== -1 ? config.api.base_url_test : config.api.base_url_dev;

export default config;